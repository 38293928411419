import moment from 'moment';

export const CurrencyFormat = ",##0.00"
export const DateTimeFormat = "dd.MM.yyyy HH:mm:ss"
export const DateFormat = "dd.MM.yyyy"
export const DateFormat2 = "dd-MM-yyyy"
const DateFormat3 = "yyyy-MM-DD"

export const utcToLocal = date => {
  const m = moment(date).utcOffset(0, true) // treats date as utc
  return m.local().format("DD.MM.yyyy")
}
const utcToCet = utcDate => {   
  var cestDateStart = moment('03.01.'+moment().year()+' 01:00:00').endOf('month').startOf('week')
  var cestDateFinish = moment('10.01.'+moment().year()+' 01:00:00').endOf('month').startOf('week')
  var result; 
  if(utcDate >= cestDateStart && utcDate <= cestDateFinish) {
      result = utcDate.add(2, 'hours');
    } else {
      result = utcDate.add(1, 'hours');
  }  
  return result;
}
export const utcToCetText = utcDate => { 
  const defaultDate = moment("0001-01-01 00:00:00", "yyyy-MM-DD HH:mm:ss");
  const utcDateMoment = moment(utcDate)
  if(utcDateMoment.isSame(defaultDate))
    return "";  
  return utcToCet(utcDateMoment).format("DD.MM.yyyy HH:mm:ss");
}
export const parseDate = date => {
  return moment(date).utcOffset(0, true).format(DateFormat3)
}

export const renderSaleDateCell = x => {
  return <div>
    <span>{x.data?.SaleDate !== null ? utcToLocal(x.data.SaleDate) : ''}</span>
  </div>
}

export const renderReceivedDateCell = x => {
  return <div>
    <span>{x.data?.DateReceivedUtc !== null ? utcToCetText(x.data.DateReceivedUtc) : ''}</span>
  </div>
}

export const renderDateCell = date => {
  return <div>
    <span>{date !== null ? utcToCetText(date) : ''}</span>
  </div>
}

export const renderCreationDateCell = x => {
  return <div>
    <span>{ x.data?.CreationDateUtc !== null ? utcToCetText(x.data.CreationDateUtc) : ''}</span>
  </div>
}

export const renderModificationDateCell = x => {
  return <div>
    <span>{ x.data?.ModificationDateUtc !== null ? utcToCetText(x.data.ModificationDateUtc) : ''}</span>
  </div>
}

export const renderDateFromUtcCell = x => {
  return <div>
    <span>{ x.data?.DateFromUtc !== null ? utcToCetText(x.data.DateFromUtc) : ''}</span>
  </div>
}

export const renderDateToUtcCell = x => {
  return <div>
    <span>{ x.data?.DateToUtc !== null ? utcToCetText(x.data.DateToUtc) : ''}</span>
  </div>
}

export const renderSourceCell = x => {
  return <div>
    <span>{x.data?.FKSystem !== null ? x.data?.FKSystem : ''} {x.data?.ManualUpload ? 'R' : ''}</span>
  </div>
}

export const renderIsDispatchCell = (x, t) => {
  return <div>
    <span>{x.data?.IsDispatch ? t("#_cellrenderers_3") : t("#_cellrenderers_4")}</span>
  </div>
}

export const renderTrueFalseCell = (x, t) => {
  return <div>
    <span>{x ? t("#_cellrenderers_5") : t("#_cellrenderers_6")}</span>
  </div>
}

export const renderBusinessErrorDetail = x => {
  const message = x.data?.BusinessError?.DetailedMessage
  return <div>
  <span>{message?.length > 120 ? message.substring(0,120) + "..." : message}</span>
</div>
}

export const renderSubject3role = (row, t, dataSource, prefix) =>
{
  const sub3role = row.data.Subject3Role;

  return <div>
    <span>{dataSource?.map(x => x.value)?.includes(sub3role) ? t(`${prefix}${sub3role.toLowerCase()}`) : ''}</span>
  </div>
}

export const renderDraftStatusCell = (t) => {
  return x => <div>
    <span>{x.value === "InProgress" ? t("draft-status-edit") : t("draft-status-ready")}</span>
  </div>
} 
