import { FilterBuilder, LoadIndicator } from "devextreme-react";
import { CustomOperation } from "devextreme-react/cjs/filter-builder";
import { useEffect, useState } from "react";
import { XfXApi } from "api/XfXApi";
import { FilterBuilderElement } from "api/openapi";
import { useTranslation } from "react-i18next";

type ConditionEditorProps = {
    defaultValue: string | null;
    setValue: (values: string) => void;
}

let fields: any[] | null = null; // this can be static, no need to be in state

export const ConditionEditor: React.FC<ConditionEditorProps> = ({ defaultValue, setValue }) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(fields === null);

    useEffect(() => {
        if (fields != null) return
        XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationConditionBuilderGet(XfXApi.GetTenantId()).then((res) => {
            const f = res.data.map((x: FilterBuilderElement) => {
                let field: any = { dataField: x.permanentName, dataType: x.filterDataType, caption: x.label ? t(x.label) : x.permanentName };

                if (x.dataSource && x.dataSource.length > 0)
                    field.lookup = { dataSource: x.dataSource.map(x => ({ Key: x.key, Value: t(x.value ?? "") })), valueExpr: "Key", displayExpr: "Value" };

                if (field.dataType === "date") {
                    field.editorOptions = { dateSerializationFormat: "yyyy-MM-dd" };
                }

                return field;
            })
            fields = f;
            setIsLoading(fields === null)
        });
    }, []);

    if (isLoading) return <LoadIndicator></LoadIndicator>;

    const defaultValueParsed = defaultValue ? JSON.parse(defaultValue) : null;
    return <FilterBuilder
        fields={fields ?? []}
        defaultValue={defaultValueParsed}
        groupOperations={["and", "or"]}
        onValueChanged={(v) => { setValue(v.value); }}
    >
        <CustomOperation
            name="equalToField"
            caption="Równe wartości pola"
            icon="equal"
            dataTypes={['string', 'number', 'date', 'boolean', 'object', 'datetime']}
        // customizeText={customizeText}
        // editorComponent={EqualEditorComponent}
        />
        <CustomOperation
            name="notEqualToField"
            caption="Różne od wartości pola"
            icon="notequal"
            dataTypes={['string', 'number', 'date', 'boolean', 'object', 'datetime']}
        // customizeText={customizeText}
        // editorComponent={NotEqualEditorComponent}
        />
        <CustomOperation
            name="greaterThanField"
            caption="Większe od wartości pola"
            icon="greater"
            dataTypes={['number', 'date', 'datetime']}
        // customizeText={customizeText}
        // editorComponent={GreaterThanEditorComponent}
        />
        <CustomOperation
            name="lessThanField"
            caption="Mniejsze od wartości pola"
            icon="less"
            dataTypes={['number', 'date', 'datetime']}
        // customizeText={customizeText}
        // editorComponent={LessThanEditorComponent}
        />
        <CustomOperation
            name="isContractor"
            caption="NIP jest w bazie kontrahentów"
            icon="user"
            dataTypes={['string']}
            hasValue={false}
        />
        <CustomOperation
            name="isOnNipList"
            caption="Jest na liście NIPów"
            icon="group"
            dataTypes={['string']}
        // customizeText={customizeNipText}
        // editorComponent={IsOnNipListEditorComponent}
        />
    </FilterBuilder>
}