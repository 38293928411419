export const OrganizationColumnRenderer = (x, t) => {
    if (x.value.listType === 0) return <>{t("#_roleseditor_1")}:<ul>{x.value.allowed.map((d) => <li key={d}>{d}</li>)}</ul></>
    if (x.value.listType === 1) return <>{t("#_roleseditor_2")}:<ul>{x.value.forbidden.map((d) => <li key={d}>{d}</li>)}</ul></>
    if (x.value.listType === 2) return t("#_renderers_1")
}

export const PrivilegesRenderer = (x, id2PriviligeName) => {
    try {
        return x?.value?.map((d) => <li key={d}>{id2PriviligeName[d]}</li>)
    } catch (error) {
        return "-"
    }
}

export const TagsColumnRenderer = ({ tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, tTag1, tTag2, tTag3, tTag4, tTag5, t }) => {
    if ((!tag1 || (tag1?.allowed?.length === 0 && tag1?.forbidden?.length === 0)) &&
        (!tag2 || (tag2?.allowed?.length === 0 && tag2?.forbidden?.length === 0)) &&
        (!tag3 || (tag3?.allowed?.length === 0 && tag3?.forbidden?.length === 0)) &&
        (!tag4 || (tag4?.allowed?.length === 0 && tag4?.forbidden?.length === 0)) &&
        (!tag5 || (tag5?.allowed?.length === 0 && tag5?.forbidden?.length === 0)) &&
        (!tag6 || (tag6?.allowed?.length === 0 && tag6?.forbidden?.length === 0)) &&
        (!tag7 || (tag7?.allowed?.length === 0 && tag7?.forbidden?.length === 0)) &&
        (!tag8 || (tag8?.allowed?.length === 0 && tag8?.forbidden?.length === 0)) &&
        (!tag9 || (tag9?.allowed?.length === 0 && tag9?.forbidden?.length === 0)) &&
        (!tag10 || (tag10?.allowed?.length === 0 && tag10?.forbidden?.length === 0)) &&
        (!tTag1 || (tTag1?.allowed?.length === 0 && tTag1?.forbidden?.length === 0)) &&
        (!tTag2 || (tTag2?.allowed?.length === 0 && tTag2?.forbidden?.length === 0)) &&
        (!tTag3 || (tTag3?.allowed?.length === 0 && tTag3?.forbidden?.length === 0)) &&
        (!tTag4 || (tTag4?.allowed?.length === 0 && tTag4?.forbidden?.length === 0)) &&
        (!tTag5 || (tTag5?.allowed?.length === 0 && tTag5?.forbidden?.length === 0)))
    {
        return t("#_renderers_2")
    }

    let elements = []

    const addTag = (tag, label) => {
        if (tag) {
            if (tag.listType === 0) {
                elements.push(<>{label} {t("#_roleseditor_1")}:<ul>{tag.allowed.map((d) => <li key={d}>{d}</li>)}</ul></>)
            } else {
                elements.push(<>{label} {t("#_roleseditor_2")}:<ul>{tag.forbidden.map((d) => <li key={d}>{d}</li>)}</ul></>)
            }
        }
    }

    addTag(tag1, "Tag 1")
    addTag(tag2, "Tag 2")
    addTag(tag3, "Tag 3")
    addTag(tag4, "Tag 4")
    addTag(tag5, "Tag 5")
    addTag(tag6, "Tag 6")
    addTag(tag7, "Tag 7")
    addTag(tag8, "Tag 8")
    addTag(tag9, "Tag 9")
    addTag(tag10, "Tag 10")
    addTag(tTag1, "TTag 1")
    addTag(tTag2, "TTag 2")
    addTag(tTag3, "TTag 3")
    addTag(tTag4, "TTag 4")
    addTag(tTag5, "TTag 5")

    return <ul>
        {elements.map((d) => <li key={d}>{d}</li>)}
    </ul>
}

export const RestrictionsColumnRenderer = (label, nips, tags, t) => {
    if (nips === undefined) return ""
    return <>
        <ol>
            <li>
                <div>{label}</div>
                {nips.listType === 2 && <>Wszyscy</>}
                {(nips.listType !== 2 && (nips.allowed.length > 0 || nips.forbidden.length > 0)) && <>
                    {nips.allowed.length > 0 && <div>
                        <div>{t("#_roleseditor_1")}</div>
                        <ul>
                            {nips.allowed.map((d) => <li key={d}>{d}</li>)}
                        </ul>
                    </div>}

                    {nips.forbidden.length > 0 && <div>
                        <div>{t("#_roleseditor_2")}</div>
                        <ul>
                            {nips.forbidden.map((d) => <li key={d}>{d}</li>)}
                        </ul>
                    </div>}
                </>

                }
            </li>
            {tags !== undefined &&
                <li>
                    <div>{t("#_roleseditor_14")}</div>
                    {TagsColumnRenderer({ ...tags, t })}
                </li>
            }
        </ol>
    </>
}

export const SelfInvoicingAccessInRepositoriesRenderer = (salesSelfInvoicingVisibility, selfInvoiceSalesAccessLabels, purchaseSelfInvoicingVisibility, selfInvoicePurchaseAccessLabels, t) => {
    return <>
        <ol>
            <li>
                <div>{t("#_roleseditor_86")}</div>
                {salesSelfInvoicingVisibilityRenderer(salesSelfInvoicingVisibility, selfInvoiceSalesAccessLabels)}
            </li>
            <li>
                <div>{t("#_roleseditor_87")}</div>
                {purchaseSelfInvoicingVisibilityRenderer(purchaseSelfInvoicingVisibility, selfInvoicePurchaseAccessLabels)}
            </li>
        </ol>
    </>
}

export const AccountingSystemsRestrictionsColumnRenderer = (x, t) => {
    const salesAccountingSystemRestrictionsDetails = salesAccountingSystemsRestrictionsDetails(x, t)
    const purchaseAccountingSystemRestrictionsDetails = purchaseAccountingSystemsRestrictionsDetails(x, t)

    return <>
        <ol>
            <li>
                <div>{salesAccountingSystemRestrictionsDetails.Label}</div>
                {salesAccountingSystemRestrictionsDetails.Content}
            </li>
            <li>
                <div>{purchaseAccountingSystemRestrictionsDetails.Label}</div>
                {purchaseAccountingSystemRestrictionsDetails.Content}
            </li>
        </ol>
    </>
}

const salesSelfInvoicingVisibilityRenderer = (salesSelfInvoicingVisibility, selfInvoiceSalesAccessLabels) => {
    return selfInvoiceSalesAccessLabels[salesSelfInvoicingVisibility]
}

const purchaseSelfInvoicingVisibilityRenderer = (purchaseSelfInvoicingVisibility, selfInvoicePurchaseAccessLabels) => {
    return selfInvoicePurchaseAccessLabels[purchaseSelfInvoicingVisibility]
}

const salesAccountingSystemsRestrictionsDetails = (x, t) => {
    if (x.salesAccountingSystems.listType === 0) return { Label: t("#_renderers_4"), Content: <ul>{x.salesAccountingSystems.allowed.map((d) => <li key={d}>{d}</li>)}</ul>}
    if (x.salesAccountingSystems.listType === 1) return { Label: t("#_renderers_5"), Content: <ul>{x.salesAccountingSystems.forbidden.map((d) => <li key={d}>{d}</li>)}</ul>}
    if (x.salesAccountingSystems.listType === 2) return { Label: t("#_renderers_8"), Content: t("#_renderers_3")}
}

const purchaseAccountingSystemsRestrictionsDetails = (x, t) => {
    if (x.purchaseAccountingSystems.listType === 0) return { Label: t("#_renderers_6"), Content: <ul>{x.purchaseAccountingSystems.allowed.map((d) => <li key={d}>{d}</li>)}</ul>}
    if (x.purchaseAccountingSystems.listType === 1) return { Label: t("#_renderers_7"), Content: <ul>{x.purchaseAccountingSystems.forbidden.map((d) => <li key={d}>{d}</li>)}</ul>}
    if (x.purchaseAccountingSystems.listType === 2) return { Label: t("#_renderers_9"), Content: t("#_renderers_3")}
}
