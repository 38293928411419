import { useEffect, useState, useRef, useCallback } from "react"
import 'devextreme-react/autocomplete';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Selection,
  HeaderFilter,
  Scrolling,
  Search
} from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';

import GenericToolbar from '../Toolbars/GenericToolbar'
import { XfXApi } from 'api/XfXApi';
import { useRefreshButton } from "utils/refresh";
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import GenericPopup from "components/popup/GenericPopup"
import { OrganizationColumnRenderer, PrivilegesRenderer, RestrictionsColumnRenderer, SelfInvoicingAccessInRepositoriesRenderer, AccountingSystemsRestrictionsColumnRenderer } from './Renderers'

import RolesEditor from './RolesEditor'
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import DeletionPopup from 'components/popup/DeletionPopup';

const emptyState = t => {
  const white = t("#_roles_1")
  const saleSelfInvoicingAll =  t("#_roleseditor_88")
  const purchaseSelfInvoicingAll =  t("#_roleseditor_91")
  return {
    id: null,
    name: null,
    description: null,
    privilegeIds: [],
    usersIds: [],
    organizationIds: [],
    organizationsList: [],

    organizationListMode: white,
    saleNipsListMode: white,
    purchaseNipsListMode: white,

    salesSelfInvoicingVisibility: saleSelfInvoicingAll,
    purchaseSelfInvoicingVisibility: purchaseSelfInvoicingAll,
    selectedAdministrationPrivileges: [],
    selectedPurchasePrivileges: [],
    selectedSalesPrivileges: [],

    saleNipsList: [], 
    purchaseNipsList: [],

    saleAccountingSystems: [], 
    purchaseAccountingSystems: [],

    purchaseTags: {
      tag1Values: null, tag1Mode: white,
      tag2Values: null, tag2Mode: white,
      tag3Values: null, tag3Mode: white,
      tag4Values: null, tag4Mode: white,
      tag5Values: null, tag5Mode: white,
      tag6Values: null, tag6Mode: white,
      tag7Values: null, tag7Mode: white,
      tag8Values: null, tag8Mode: white,
      tag9Values: null, tag9Mode: white,
      tag10Values: null, tag10Mode: white,
      tTag1Values: null, tTag1Mode: white,
      tTag2Values: null, tTag2Mode: white,
      tTag3Values: null, tTag3Mode: white,
      tTag4Values: null, tTag4Mode: white,
      tTag5Values: null, tTag5Mode: white
    },

    saleTags: {
      tag1Values: null, tag1Mode: white,
      tag2Values: null, tag2Mode: white,
      tag3Values: null, tag3Mode: white,
      tag4Values: null, tag4Mode: white,
      tag5Values: null, tag5Mode: white,
      tag6Values: null, tag6Mode: white,
      tag7Values: null, tag7Mode: white,
      tag8Values: null, tag8Mode: white,
      tag9Values: null, tag9Mode: white,
      tag10Values: null, tag10Mode: white,
      tTag1Values: null, tTag1Mode: white,
      tTag2Values: null, tTag2Mode: white,
      tTag3Values: null, tTag3Mode: white,
      tTag4Values: null, tTag4Mode: white,
      tTag5Values: null, tTag5Mode: white
    },

    saleAccountingSystemsMode: white,
    purchaseAccountingSystemsMode: white

  }
}

const userColums = ['login']



const validationRolesFormGroup = "validationOrganizationFormGroup";

function mapUsers(users) {
  return users.map(v => ({ id: v.id, login: v.login }))
}

const Roles = () => {
  const { t } = useTranslation()
  const WHITE_BLACK_LABELS = [t("#_roles_1"), t("#_roles_2")]
  const SELF_INVOICE_SALES_ACCESS_LABELS = [t("#_roleseditor_88"), t("#_roleseditor_89"), t("#_roleseditor_90")]
  const SELF_INVOICE_PURCHASE_ACCESS_LABELS = [t("#_roleseditor_91"), t("#_roleseditor_92"), t("#_roleseditor_93")]
  const editPopupTitle = t("#_roles_4")
  const addPopupTitle = t("#_roles_6")

  const [rolesWithUsersAndPrivs, setrolesWithUsersAndPrivs] = useState([])

  const [allSalesPrivileges, setAllSalesPrivileges] = useState([])
  const [allPurchasePrivileges, setAllPurchasePrivileges] = useState([])
  const [allAdministrationPrivileges, setAllAdministrationPrivileges] = useState([])

  const [allSalesPrivilegesDataSource, setAllSalesPrivilegesDataSource] = useState()
  const [allPurchasePrivilegesDataSource, setAllPurchasePrivilegesDataSource] = useState()
  const [allAdministrationPrivilegesDataSource, setAllAdministrationPrivilegesDataSource] = useState()
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const [users, setUsers] = useState([])
  const [usersValue, setUsersValue] = useState([])

  const [salesFkValue, setSalesFkValue] = useState([])
  const [purchaseFkValue, setPurchaseFkValue] = useState([])

  const [popupTitle, setPopupTitle] = useState(editPopupTitle)
  const [roleEdit, setRoleEdit] = useState(false)

  const [organizations, setOrganizations] = useState([])
  const [allOrganizationsCustomStore, setAllOrganizationsCustomStore] = useState()

  const [selectedItem, setSelectedItem] = useState(null)

  const vgRolesForm = useRef(null);

  const [state, setState] = useState(emptyState(t))

  const [id2PriviligeName, setId2PriviligeName] = useState(null)

  const [isVisible, setIsVisible] = useState(false)

  const treeViewRefSaleColumnsForRestrictions = useRef()
  const treeViewRefPurchaseColumnsForRestrictions = useRef()
  const treeViewRefOtherColumnsForRestrictions = useRef()

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const isWhiteListModeSelected = (mode) => mode === WHITE_BLACK_LABELS[0]
  const salesInvoiceTypeSelectionValue = (mode) => SELF_INVOICE_SALES_ACCESS_LABELS.indexOf(mode);
  const purchaseInvoiceTypeSelectionValue = (mode) => SELF_INVOICE_PURCHASE_ACCESS_LABELS.indexOf(mode);

  const saveAction = useCallback(async (e) => {
    try {
      let privs = new Set()
      state.selectedAdministrationPrivileges.forEach(item => privs.add(item))
      state.selectedPurchasePrivileges.forEach(item => privs.add(item))
      state.selectedSalesPrivileges.forEach(item => privs.add(item))

      let request = {
        id: state.id,
        name: state.name,
        usersIds: state.usersIds,
        organizationIds: state.organizationIds,
        description: state.description
      }
      request.privilegeIds = Array.from(privs)
      request.privileges = Array.from(privs)

      const organizationsAsWhiteList = isWhiteListModeSelected(state.organizationListMode)
      const getNips = list => organizations.filter(x => list.includes(x.id)).map(x => x.nip)
      request.organizations = {
        allowed: organizationsAsWhiteList ? getNips(state.organizationsList) : [],
        forbidden: organizationsAsWhiteList ? [] : getNips(state.organizationsList)
      }

      const saleNipsAsWhiteList = isWhiteListModeSelected(state.saleNipsListMode)
      request.salesNips = {
        allowed: saleNipsAsWhiteList ? state.saleNipsList : [],
        forbidden: saleNipsAsWhiteList ? [] : state.saleNipsList
      }

      const purchaseNipsAsWhiteList = isWhiteListModeSelected(state.purchaseNipsListMode)
      request.purchaseNips = {
        allowed: purchaseNipsAsWhiteList ? state.purchaseNipsList : [],
        forbidden: purchaseNipsAsWhiteList ? [] : state.purchaseNipsList
      }

      request.salesSelfInvoicingVisibility = salesInvoiceTypeSelectionValue(state.salesSelfInvoicingVisibility)
      request.purchaseSelfInvoicingVisibility = purchaseInvoiceTypeSelectionValue(state.purchaseSelfInvoicingVisibility)

      const purchaseAccountingSystemsAsWhiteList = isWhiteListModeSelected(state.purchaseAccountingSystemsMode)
      request.purchaseAccountingSystems = {
        allowed: purchaseAccountingSystemsAsWhiteList ? state.purchaseAccountingSystems : [],
        forbidden: purchaseAccountingSystemsAsWhiteList ? [] : state.purchaseAccountingSystems
      }

      const saleAccountingSystemsAsWhiteList = isWhiteListModeSelected(state.saleAccountingSystemsMode)
      request.salesAccountingSystems = {
        allowed: saleAccountingSystemsAsWhiteList ? state.saleAccountingSystems : [],
        forbidden: saleAccountingSystemsAsWhiteList ? [] : state.saleAccountingSystems
      } 

      const getTagForRequest = (mode, values) => {
        if (values === null) return null
        const tag1AsWhiteList = isWhiteListModeSelected(mode)
        return {
          allowed: tag1AsWhiteList ? values : [],
          forbidden: tag1AsWhiteList ? [] : values
        }
      }

      request.saleTags = {
        tag1: getTagForRequest(state.saleTags.tag1Mode, state.saleTags.tag1Values),
        tag2: getTagForRequest(state.saleTags.tag2Mode, state.saleTags.tag2Values),
        tag3: getTagForRequest(state.saleTags.tag3Mode, state.saleTags.tag3Values),
        tag4: getTagForRequest(state.saleTags.tag4Mode, state.saleTags.tag4Values),
        tag5: getTagForRequest(state.saleTags.tag5Mode, state.saleTags.tag5Values),
        tag6: getTagForRequest(state.saleTags.tag6Mode, state.saleTags.tag6Values),
        tag7: getTagForRequest(state.saleTags.tag7Mode, state.saleTags.tag7Values),
        tag8: getTagForRequest(state.saleTags.tag8Mode, state.saleTags.tag8Values),
        tag9: getTagForRequest(state.saleTags.tag9Mode, state.saleTags.tag9Values),
        tag10: getTagForRequest(state.saleTags.tag10Mode, state.saleTags.tag10Values),
        tTag1: getTagForRequest(state.saleTags.tTag1Mode, state.saleTags.tTag1Values),
        tTag2: getTagForRequest(state.saleTags.tTag2Mode, state.saleTags.tTag2Values),
        tTag3: getTagForRequest(state.saleTags.tTag3Mode, state.saleTags.tTag3Values),
        tTag4: getTagForRequest(state.saleTags.tTag4Mode, state.saleTags.tTag4Values),
        tTag5: getTagForRequest(state.saleTags.tTag5Mode, state.saleTags.tTag5Values)
      }

      request.purchaseTags = {
        tag1: getTagForRequest(state.purchaseTags.tag1Mode, state.purchaseTags.tag1Values),
        tag2: getTagForRequest(state.purchaseTags.tag2Mode, state.purchaseTags.tag2Values),
        tag3: getTagForRequest(state.purchaseTags.tag3Mode, state.purchaseTags.tag3Values),
        tag4: getTagForRequest(state.purchaseTags.tag4Mode, state.purchaseTags.tag4Values),
        tag5: getTagForRequest(state.purchaseTags.tag5Mode, state.purchaseTags.tag5Values),
        tag6: getTagForRequest(state.purchaseTags.tag6Mode, state.purchaseTags.tag6Values),
        tag7: getTagForRequest(state.purchaseTags.tag7Mode, state.purchaseTags.tag7Values),
        tag8: getTagForRequest(state.purchaseTags.tag8Mode, state.purchaseTags.tag8Values),
        tag9: getTagForRequest(state.purchaseTags.tag9Mode, state.purchaseTags.tag9Values),
        tag10: getTagForRequest(state.purchaseTags.tag10Mode, state.purchaseTags.tag10Values),
        tTag1: getTagForRequest(state.purchaseTags.tTag1Mode, state.purchaseTags.tTag1Values),
        tTag2: getTagForRequest(state.purchaseTags.tTag2Mode, state.purchaseTags.tTag2Values),
        tTag3: getTagForRequest(state.purchaseTags.tTag3Mode, state.purchaseTags.tTag3Values),
        tTag4: getTagForRequest(state.purchaseTags.tTag4Mode, state.purchaseTags.tTag4Values),
        tTag5: getTagForRequest(state.purchaseTags.tTag5Mode, state.purchaseTags.tTag5Values)
      }

      request.saleColumns = { forbidden: state.forbiddenSaleColumns }
      request.purchaseColumns = { forbidden: state.forbiddenPurchaseColumns }
      request.otherColumns = { forbidden: state.forbiddenOtherColumns }

      if (!roleEdit) {
        state.id = -1
      }

      const response = roleEdit
        ? await XfXApi.Role.apiTenantIdRolePut(XfXApi.GetTenantId(), request)
        : await XfXApi.Role.apiTenantIdRolePost(XfXApi.GetTenantId(), request)

      if (response.status === 200) {
        notify({
          message: `${roleEdit ? t("#_roles_33") : t("#_roles_34")}`,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
      }
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: `${roleEdit ? t("#_roles_35") : t("#_roles_36")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
    }
    finally {
      fetchRolesWithUsersAndPrivs()
    }
  }, [organizations, roleEdit, state])

  const submit = async (e) => {
    const validationResult = vgRolesForm.current.instance.validate()
    if (!validationResult.isValid)
      return false
    else {
      saveAction(e)
      return true
    }
  }

  const fetchRolesWithUsersAndPrivs = async () => {
    try {
      const { data } = await XfXApi.Role.apiTenantIdRoleRolesUsersPrivilegesGet(XfXApi.GetTenantId())
      setrolesWithUsersAndPrivs(data)
      if (selectedItem !== null) {
        const updatedSelctedItem = data.find(x => x.id === selectedItem.id)
        setSelectedItem(updatedSelctedItem)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const unselectAllRestrictionsDropdowns = () => {
    treeViewRefSaleColumnsForRestrictions?.current?.instance?.unselectAll()
    treeViewRefPurchaseColumnsForRestrictions?.current?.instance?.unselectAll()
    treeViewRefOtherColumnsForRestrictions?.current?.instance?.unselectAll()
  }

  useRefreshButton(() => fetchRolesWithUsersAndPrivs())

  useEffect(() => {
    fetchRolesWithUsersAndPrivs()
  }, [])

  const fetchOrganizations = async () => {
    try {
      const result = await XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
      setOrganizations(result.data)
      setAllOrganizationsCustomStore(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchOrganizations()
  }, [])

  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        const { data: { stateObject } } = await XfXApi.Role.apiTenantIdRolePrivilegesGet(XfXApi.GetTenantId())

        let id2PriviligeName = {}
        stateObject.dispatch.forEach(x => id2PriviligeName[x.id] = x.name)
        stateObject.reception.forEach(x => id2PriviligeName[x.id] = x.name)
        stateObject.administration.forEach(x => id2PriviligeName[x.id] = x.name)
        setId2PriviligeName(id2PriviligeName)

        setAllSalesPrivileges(stateObject.dispatch)
        setAllPurchasePrivileges(stateObject.reception)
        setAllAdministrationPrivileges(stateObject.administration)

        setAllSalesPrivilegesDataSource(makeAsyncDataSource(stateObject.dispatch))
        setAllPurchasePrivilegesDataSource(makeAsyncDataSource(stateObject.reception))
        setAllAdministrationPrivilegesDataSource(makeAsyncDataSource(stateObject.administration))

      } catch (error) {
        console.error(error)
      }
    }

    fetchPrivileges()
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data: { stateObject } } = await XfXApi.User.apiTenantIdUserUsersGet(XfXApi.GetTenantId())
        setUsers(makeAsyncDataSource(mapUsers(stateObject)))
      } catch (error) {
        console.error(error)
      }
    }

    fetchUsers()
  }, [])

  const deletionPopup = DeletionPopup({
    onConfirm: () =>{
      XfXApi.Role.apiTenantIdRoleDelete(selectedItem.id, XfXApi.GetTenantId())
            .then(fetchRolesWithUsersAndPrivs)
    },
    content: deletionPopupContent, 
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible, 
    setIsVisible: setDeletionPopupVisible, 
    t: t
  })

  const deleteRole = useCallback(() => {
    if (selectedItem === null) 
      return

    setDeletionPopupContent(t("#_DeletionPopup"))
    deletionPopup.show()
  }, [selectedItem])

  const editor = RolesEditor({
    state: state,
    setState: setState,
    allSalesPrivileges: allSalesPrivilegesDataSource,
    allPurchasePrivileges: allPurchasePrivilegesDataSource,
    allAdministrationPrivileges: allAdministrationPrivilegesDataSource,
    dataSource: users,
    usersValue: usersValue,
    setUsersValue: setUsersValue,
    userColums: userColums,
    salesFkValue: salesFkValue, 
    setSalesFkValue: setSalesFkValue,
    purchaseFkValue: purchaseFkValue, 
    setPurchaseFkValue: setPurchaseFkValue,
    vgRolesForm: vgRolesForm,
    validationRolesFormGroup: validationRolesFormGroup,
    allOrganizationsCustomStore: allOrganizationsCustomStore,
    treeViewRefSaleColumnsForRestrictions: treeViewRefSaleColumnsForRestrictions,
    treeViewRefPurchaseColumnsForRestrictions: treeViewRefPurchaseColumnsForRestrictions,
    treeViewRefOtherColumnsForRestrictions: treeViewRefOtherColumnsForRestrictions,
    selfInvoiceSalesAccessLabels: SELF_INVOICE_SALES_ACCESS_LABELS,
    selfInvoicePurchaseAccessLabels: SELF_INVOICE_PURCHASE_ACCESS_LABELS,
    whiteBlackLabels: WHITE_BLACK_LABELS
  })

  const popupToolbarItems = commonPopupToolbarItems({ 
    onSave: async () => {
      const sent = await submit()
      if (sent) {
        popup.close()
        setState({ ...emptyState(t) })
        unselectAllRestrictionsDropdowns()
      }
    }, 
    onCancel: () => {
      popup.close()
      setState({ ...emptyState(t) })
      unselectAllRestrictionsDropdowns()
    }, t: t 
  })

  const popup = GenericPopup({ 
    onHiding: () => popup.close(),
    content:
    <div>
      {editor}
    </div>,
    toolbarItems: popupToolbarItems,
    title: popupTitle,
    width: "812",
    height: "80%",
    isVisible: isVisible,
    setIsVisible: setIsVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const editRole = useCallback(() => {
    if (selectedItem === null) return
    const selectedRole = JSON.parse(JSON.stringify(selectedItem)) //deep copy required

    setUsersValue(selectedRole.usersIds)

    let organizationsList = selectedRole.organizations?.listType === 0 ?
      organizations.filter(x => selectedRole.organizations.allowed.includes(x.nip)).map(x => x.id) :
      organizations.filter(x => selectedRole.organizations.forbidden.includes(x.nip)).map(x => x.id)

    const getTagValues = (x) => x ? (x.listType === 0 ? x.allowed : x.forbidden) : null
    const getTagMode = (x) => x ? (x.listType === 0 ? WHITE_BLACK_LABELS[0] : WHITE_BLACK_LABELS[1]) : WHITE_BLACK_LABELS[0]

    const newState = {
      id: selectedRole.id,
      name: selectedRole.name,
      description: selectedRole.description,
      rolePrivileges: [],
      privilegeIds: selectedRole.privilegeIds,
      roleUsers: [],
      usersIds: selectedRole.usersIds,
      roleOrganizations: [],
      organizationIds: selectedRole.organizationIds,


      organizationsList: organizationsList,

      organizationListMode: selectedRole.organizations?.listType === 1 ? WHITE_BLACK_LABELS[1] : WHITE_BLACK_LABELS[0],
      saleNipsListMode: selectedRole.salesNips?.listType === 1 ? WHITE_BLACK_LABELS[1] : WHITE_BLACK_LABELS[0],
      purchaseNipsListMode: selectedRole.purchaseNips?.listType === 1 ? WHITE_BLACK_LABELS[1] : WHITE_BLACK_LABELS[0],

      saleNipsList: selectedRole.salesNips?.listType === 0 ? selectedRole.salesNips?.allowed : selectedRole.salesNips?.forbidden,
      saleAccountingSystems: selectedRole.salesAccountingSystems?.listType === 0 ? selectedRole.salesAccountingSystems?.allowed : selectedRole.salesAccountingSystems?.forbidden,

      purchaseAccountingSystemsMode: selectedRole.purchaseAccountingSystems?.listType === 1 ? WHITE_BLACK_LABELS[1] : WHITE_BLACK_LABELS[0],
      saleAccountingSystemsMode: selectedRole.salesAccountingSystems?.listType === 1 ? WHITE_BLACK_LABELS[1] : WHITE_BLACK_LABELS[0],


      purchaseNipsList: selectedRole.purchaseNips?.listType === 0 ? selectedRole.purchaseNips?.allowed : selectedRole.purchaseNips?.forbidden,
      purchaseAccountingSystems: selectedRole.purchaseAccountingSystems?.listType === 0 ? selectedRole.purchaseAccountingSystems?.allowed : selectedRole.purchaseAccountingSystems?.forbidden,

      
      selectedSalesPrivileges: allSalesPrivileges.filter(x => selectedRole.privilegeIds.includes(x.id)).map(x => x.id),
      selectedPurchasePrivileges: allPurchasePrivileges.filter(x => selectedRole.privilegeIds.includes(x.id)).map(x => x.id),
      selectedAdministrationPrivileges: allAdministrationPrivileges.filter(x => selectedRole.privilegeIds.includes(x.id)).map(x => x.id),

      salesSelfInvoicingVisibility: SELF_INVOICE_SALES_ACCESS_LABELS[selectedRole.salesSelfInvoicingVisibility],
      purchaseSelfInvoicingVisibility: SELF_INVOICE_PURCHASE_ACCESS_LABELS[selectedRole.purchaseSelfInvoicingVisibility],

      saleTags: {
        tag1Values: getTagValues(selectedRole.saleTags.tag1), tag1Mode: getTagMode(selectedRole.saleTags.tag1),
        tag2Values: getTagValues(selectedRole.saleTags.tag2), tag2Mode: getTagMode(selectedRole.saleTags.tag2),
        tag3Values: getTagValues(selectedRole.saleTags.tag3), tag3Mode: getTagMode(selectedRole.saleTags.tag3),
        tag4Values: getTagValues(selectedRole.saleTags.tag4), tag4Mode: getTagMode(selectedRole.saleTags.tag4),
        tag5Values: getTagValues(selectedRole.saleTags.tag5), tag5Mode: getTagMode(selectedRole.saleTags.tag5),
        tag6Values: getTagValues(selectedRole.saleTags.tag6), tag6Mode: getTagMode(selectedRole.saleTags.tag6),
        tag7Values: getTagValues(selectedRole.saleTags.tag7), tag7Mode: getTagMode(selectedRole.saleTags.tag7),
        tag8Values: getTagValues(selectedRole.saleTags.tag8), tag8Mode: getTagMode(selectedRole.saleTags.tag8),
        tag9Values: getTagValues(selectedRole.saleTags.tag9), tag9Mode: getTagMode(selectedRole.saleTags.tag9),
        tag10Values: getTagValues(selectedRole.saleTags.tag10), tag10Mode: getTagMode(selectedRole.saleTags.tag10),
        tTag1Values: getTagValues(selectedRole.saleTags.tTag1), tTag1Mode: getTagMode(selectedRole.saleTags.tTag1),
        tTag2Values: getTagValues(selectedRole.saleTags.tTag2), tTag2Mode: getTagMode(selectedRole.saleTags.tTag2),
        tTag3Values: getTagValues(selectedRole.saleTags.tTag3), tTag3Mode: getTagMode(selectedRole.saleTags.tTag3),
        tTag4Values: getTagValues(selectedRole.saleTags.tTag4), tTag4Mode: getTagMode(selectedRole.saleTags.tTag4),
        tTag5Values: getTagValues(selectedRole.saleTags.tTag5), tTag5Mode: getTagMode(selectedRole.saleTags.tTag5),
      },

      purchaseTags: {
        tag1Values: getTagValues(selectedRole.purchaseTags.tag1), tag1Mode: getTagMode(selectedRole.purchaseTags.tag1),
        tag2Values: getTagValues(selectedRole.purchaseTags.tag2), tag2Mode: getTagMode(selectedRole.purchaseTags.tag2),
        tag3Values: getTagValues(selectedRole.purchaseTags.tag3), tag3Mode: getTagMode(selectedRole.purchaseTags.tag3),
        tag4Values: getTagValues(selectedRole.purchaseTags.tag4), tag4Mode: getTagMode(selectedRole.purchaseTags.tag4),
        tag5Values: getTagValues(selectedRole.purchaseTags.tag5), tag5Mode: getTagMode(selectedRole.purchaseTags.tag5),
        tag6Values: getTagValues(selectedRole.purchaseTags.tag6), tag6Mode: getTagMode(selectedRole.purchaseTags.tag6),
        tag7Values: getTagValues(selectedRole.purchaseTags.tag7), tag7Mode: getTagMode(selectedRole.purchaseTags.tag7),
        tag8Values: getTagValues(selectedRole.purchaseTags.tag8), tag8Mode: getTagMode(selectedRole.purchaseTags.tag8),
        tag9Values: getTagValues(selectedRole.purchaseTags.tag9), tag9Mode: getTagMode(selectedRole.purchaseTags.tag9),
        tag10Values: getTagValues(selectedRole.purchaseTags.tag10), tag10Mode: getTagMode(selectedRole.purchaseTags.tag10),
        tTag1Values: getTagValues(selectedRole.purchaseTags.tTag1), tTag1Mode: getTagMode(selectedRole.purchaseTags.tTag1),
        tTag2Values: getTagValues(selectedRole.purchaseTags.tTag2), tTag2Mode: getTagMode(selectedRole.purchaseTags.tTag2),
        tTag3Values: getTagValues(selectedRole.purchaseTags.tTag3), tTag3Mode: getTagMode(selectedRole.purchaseTags.tTag3),
        tTag4Values: getTagValues(selectedRole.purchaseTags.tTag4), tTag4Mode: getTagMode(selectedRole.purchaseTags.tTag4),
        tTag5Values: getTagValues(selectedRole.purchaseTags.tTag5), tTag5Mode: getTagMode(selectedRole.purchaseTags.tTag5),
      },
      forbiddenSaleColumns: selectedRole.saleColumns.forbidden,
      forbiddenPurchaseColumns: selectedRole.purchaseColumns.forbidden,
      forbiddenOtherColumns: selectedRole.otherColumns.forbidden,
    }

    setSalesFkValue(newState.saleAccountingSystems)
    setPurchaseFkValue(newState.purchaseAccountingSystems)

    popup.show()
    setState(newState)


    setPopupTitle(editPopupTitle)
    setRoleEdit(true)


  }, [allAdministrationPrivileges, allPurchasePrivileges, allSalesPrivileges, organizations, popup, selectedItem])

  const addRole = useCallback(() => {
    setUsersValue([])
    setSalesFkValue([])
    setPurchaseFkValue([])

    setState({ ...emptyState(t) })
    unselectAllRestrictionsDropdowns()

    setPopupTitle(addPopupTitle)
    setRoleEdit(false)

    popup.show()
  }, [popup])

  function makeAsyncDataSource(data) {
    return new CustomStore({
      loadMode: 'raw',
      key: 'id',
      load() {
        return data
      },
    });
  }

  const calculateOrganizationFilter = (value) => {
    return [["organizationsView.allowed", "contains", value], "or", ["organizationsView.forbidden", "contains", value], "or", ["organizationsView.listType", "=", 2]];
  }

  const toolbarButtons = [
    { icon: 'plus', text: t("#_roles_24"), onClick: addRole },
    { icon: 'edit', text: t("#_roles_22"), onClick: editRole, disabled: selectedItem === null },
    { icon: 'trash', text: t("#_roles_200"), onClick: deleteRole, disabled: selectedItem === null },
  ]

  return (
    <div id="dataGrid-roles">
      {popup?.popup}
      {deletionPopup.popup}
      <BreadCrumbs node={getNode({ componentName: 'Roles', t })}></BreadCrumbs>
      <div className="dataGrid">
        <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_roles_16")} buttons={toolbarButtons}></GenericToolbar>
        <DataGrid
          dataSource={rolesWithUsersAndPrivs}
          keyExpr="id"
          allowColumnReordering={true}
          showColumnLines={true}
          showBorders={true}
          alignItems={"center"}
          rowAlternationEnabled={true}
          onSelectionChanged={s => {
            setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
          }}
        >
          <HeaderFilter> 
            <Search
              enabled={true}
            >
            </Search>
          </HeaderFilter>
          <FilterRow visible={true} applyFilter={true} />
          <Scrolling mode="virtual" preloadEnabled={true}/>
          <Paging defaultPageSize={100} />
          <Selection mode="single" />

          <Column dataField="id" dataType="number" caption="ID" visible={false} />
          <Column dataField="name" width={"300px"} dataType="string" caption={t("#_roles_18")} />
          <Column dataField="description" width={"280px"} dataType="string" caption={t("#_roles_20")} />
          <Column dataField="privilegeIds" width={"300px"} dataType="string" caption={t("#_roles_21")}
            cellRender={x => PrivilegesRenderer(x, id2PriviligeName)} />
          <Column dataField="organizationsView" width={"300px"} dataType="object" calculateFilterExpression={calculateOrganizationFilter} caption={t("#_roles_25")} cellRender={x => OrganizationColumnRenderer(x, t, organizations)} />
          <Column dataType="string" width={"auto"} caption={t("#_roles_26")} 
            cellRender={x => RestrictionsColumnRenderer(t("#_roles_27"), x.row.data.salesNips, x.row.data.saleTags, t)} />
          <Column dataType="string" width={"auto"} caption={t("#_roles_28")} 
            cellRender={x => RestrictionsColumnRenderer(t("#_roles_29"), x.row.data.purchaseNips, x.row.data.purchaseTags, t)} />
          <Column dataType="string" width={"auto"} caption={t("#_roles_32")} 
            cellRender={x => AccountingSystemsRestrictionsColumnRenderer(x.row.data, t)} />
          <Column dataType="string" width={"auto"} caption={t("#_roles_31")} 
            cellRender={x => SelfInvoicingAccessInRepositoriesRenderer(x.row.data.salesSelfInvoicingVisibility, SELF_INVOICE_SALES_ACCESS_LABELS,
                                                                       x.row.data.purchaseSelfInvoicingVisibility, SELF_INVOICE_PURCHASE_ACCESS_LABELS, t)} />                                                   
        </DataGrid>
      </div>
    </div>
  );
}

export default Roles
