import { useEffect, useState, useRef, useCallback } from "react"
import 'devextreme-react/autocomplete';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Selection,
  HeaderFilter,
  Scrolling,
  Label,
  Search
} from 'devextreme-react/data-grid';
import { OrganizationsColumnRenderer, RecipientsRenderer } from './Renderers'
import NotificationEditor, { emptyNotification, createDateFromTime } from './NotificationEditor'
import CustomStore from 'devextreme/data/custom_store';
import { useRefreshButton } from 'utils/refresh';

import GenericToolbar from '../Toolbars/GenericToolbar'
import { XfXApi } from 'api/XfXApi';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import DeletionPopup from 'components/popup/DeletionPopup';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";

const userColums = ['login']


function mapUsers(users) {
  return users.map(v => ({ id: v.id, login: v.login }))
}

const NotificationsConfigurations = () => {
  const { t } = useTranslation()

  const [existingConfigurations, setExistingConfigurations] = useState([])

  const [allOrganizationsCustomStore, setAllOrganizationsCustomStore] = useState()
  const [users, setUsers] = useState([])

  const [notificationId, setNotificationId] = useState(null)


  const [notificationModesCustomStore, setNotificationModesCustomStore] = useState([])
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const [visible, setVisible] = useState(false)

  const dataGridRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null)

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)
  const [availableNotificationTypes, setAvailableNotificationTypes] = useState([])

  const fetchExistingConfigurations = async () => {
    try {
      const { data } = await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationGet(XfXApi.GetTenantId())
      setExistingConfigurations(data)
      if (selectedItem !== null) {
        const updatedSelctedItem = data.find(x => x.id === selectedItem.id)
        setSelectedItem(updatedSelctedItem)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAvailableNotificationTypesKeys = async () => {
    try {
      await XfXApi.DictionaryInvoice.apiTenantIdDictionaryNotificationTypesGet(XfXApi.GetTenantId())
            .then((resp) => {
              setAvailableNotificationTypes(resp.data)
            })
    } catch (error) {
      console.error(error)
    }
  }

  useRefreshButton(fetchExistingConfigurations)

  useEffect(() => {
    fetchExistingConfigurations()
    fetchAvailableNotificationTypesKeys()
  }, [t])

  const fetchOrganizations = async () => {
    try {
      const result = await XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
      setAllOrganizationsCustomStore(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchOrganizations()
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data: { stateObject } } = await XfXApi.User.apiTenantIdUserUsersGet(XfXApi.GetTenantId())
        setUsers(makeAsyncDataSource(mapUsers(stateObject.filter(x => x.type === 0))))
      } catch (error) {
        console.error(error)
      }
    }

    fetchUsers()
  }, [])

  function makeAsyncDataSource(data) {
    return new CustomStore({
      loadMode: 'raw',
      key: 'id',
      load() {
        return data
      },
    });
  }

  const deletionPopup = DeletionPopup({
    onConfirm: () => {
      XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationIdDelete(selectedItem.id, XfXApi.GetTenantId())
        .then(fetchExistingConfigurations)
    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t
  })

  const deleteConfiguration = useCallback(() => {
    if (selectedItem === null) return
    setDeletionPopupContent(t("#_DeletionPopup"));
    deletionPopup.show();
  }, [selectedItem])


  const [notificationForEditor, setNotificationForEditor] = useState(null)

  function addConfiguration(e) {
    setNotificationForEditor(emptyNotification)
    setNotificationId(null)
    setVisible(true)
  }

  function editConfiguration(e) {
    const selected = dataGridRef.current.instance.getSelectedRowsData()
    if (selected.length === 0) return

    const notificationId = selected[0].id
    setNotificationId(notificationId)

    const loadData = async () => {
      if (notificationId) {
        await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationIdGet(notificationId, XfXApi.GetTenantId())
          .then(e => {
            const state = e.data.stateObject;
            const updatedNotification = { ...state, notificationStartTimeTmp: createDateFromTime(state.notificationStartTime) };
            setNotificationForEditor(old => updatedNotification);
            setVisible(true)
          });
      }
    };

    loadData();
  }

  const calculateOrganizationFilter = (value) => {
    return [["organizations", "contains", value]];
  }

  const toolbarButtons = [
    { icon: 'plus', text: t("#_notificationconfiguration_7"), onClick: addConfiguration },
    { icon: 'edit', text: t("#_notificationconfiguration_8"), onClick: editConfiguration, disabled: selectedItem === null || !availableNotificationTypes.map(x => x.value).includes(selectedItem?.notificationType) },
    { icon: 'trash', text: t("#_notificationconfiguration_9"), onClick: deleteConfiguration, disabled: selectedItem === null },
  ]

  const popupClose = () => { editor.close() };

  const saveButtonOptions = {
    text: t("#_notificationconfiguration_28"),
    onClick: async () => {
      const isSuccess = await editorRef.current.save()
      if (isSuccess) {
        popupClose()
        await fetchExistingConfigurations()
      }
    }
  };

  const cancelButtonOptions = {
    text: t("#_savecancelpopup_2"),
    onClick: () => {
      popupClose();
    }
  };

  const editorRef = useRef(null);
  const editorControl = <NotificationEditor
    ref={editorRef}
    setVisible={setVisible}
    notificationId={notificationId}
    userDataSource={users}
    userColums={userColums}
    notificationModesDataStore={notificationModesCustomStore}
    allOrganizationsCustomStore={allOrganizationsCustomStore}
    initialNotification={notificationForEditor}
    visible={visible}
    setBlockAutoRefresh={setBlockAutoRefresh}
  />

  const popupToolbarItems = commonPopupToolbarItems({
    t: t,
    saveButtonOptions: saveButtonOptions,
    cancelButtonOptions: cancelButtonOptions,
    onSave: async () => {


    },
    onCancel: () => {
      popupClose();
    },
    saveLabel: t("#_notificationconfiguration_28"),
    cancelLabel: t("#_savecancelpopup_2"),
    includeDraft: false,
    onSaveDraft: undefined,
    saveDraftLabel: undefined,
    saveDraftButtonOptions: undefined,
    loading: false
  });

  const editor = GenericPopup({
    buttons: [],
    title: t("#_notificationconfiguration_26"),
    content: editorControl,
    toolbarItems: popupToolbarItems,
    width: "800px",
    height: "80%",
    isVisible: visible,
    setIsVisible: setVisible,
    setBlockAutoRefresh: setBlockAutoRefresh,
    onHiding: () => setVisible(false),
    showTitle: true
  });



  return (
    <>
      {deletionPopup.popup}
      {editor.popup}
      <div id="dataGrid-configurations">
        <BreadCrumbs node={getNode({ componentName: 'Notifications', t })}></BreadCrumbs>
        <div className="dataGrid">
          <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_roles_16")} buttons={toolbarButtons}></GenericToolbar>
          {
            <DataGrid
              dataSource={existingConfigurations}
              keyExpr="id"
              allowColumnReordering={true}
              showColumnLines={true}
              showBorders={true}
              alignItems={"center"}
              rowAlternationEnabled={true}
              ref={dataGridRef}
              onSelectionChanged={s => {
                setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
              }}>
              <HeaderFilter>
                <Search
                  enabled={true}
                >
                </Search>
              </HeaderFilter>
              <FilterRow visible={true} applyFilter={true} />
              <Scrolling mode="virtual" preloadEnabled={true} />
              <Paging defaultPageSize={100} />
              <Selection mode="single" />

              <Column dataField="id" dataType="number" caption="ID" visible={false} />
              <Column dataField="name" width={"200px"} dataType="string" caption={t("#_notificationconfiguration_1")} />
              <Column dataField="organizations" calculateFilterExpression={calculateOrganizationFilter} dataType="object" caption={t("#_notificationconfiguration_2")} cellRender={OrganizationsColumnRenderer} />
              <Column dataField="notificationMode" dataType="string" caption={t("#_notificationconfiguration_3")} cellRender={x => t("NotificationKind-" + x.value)} />
              <Column dataField="notificationType" dataType="string" caption={t("#_notificationconfiguration_4")} cellRender={x => t("NotificationType-" + x.value)} />
              <Column dataField="notificationPeriod" dataType="string" caption={t("#_notificationconfiguration_5")} />
              <Column dataField="recipients" dataType="string" caption={t("#_notificationconfiguration_6")} cellRender={RecipientsRenderer} />
            </DataGrid>
          }
        </div>
      </div>
    </>
  );
}

export default NotificationsConfigurations