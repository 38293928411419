import Notify from 'devextreme/ui/notify';
import axios from 'axios';
import { InvoicesEndpoints } from 'api/XfXApi';
import { parseDate } from 'utils/cellRenderers';
import { useState } from 'react';
import { Buffer } from 'buffer';

interface InvoiceFile {
  file: File;
  name: string;
}

interface PostInvoiceDto {
  invoices: InvoiceFile[];
  manualUpload: boolean;
  vatDate?: Date;
  Tag1?: string;
  Tag2?: string;
  Tag3?: string;
  Tag4?: string;
  Tag5?: string;
  Tag6?: string;
  Tag7?: string;
  Tag8?: string;
  Tag9?: string;
  Tag10?: string;
  TTag1?: string;
  TTag2?: string;
  TTag3?: string;
  TTag4?: string;
  TTag5?: string;
  anonymize?: boolean;
  draft?: boolean;
}

interface PostInvoicesTokensCsvDto {
  invoices: InvoiceFile[];
  adjustTokens: boolean;
  Tag1?: string;
  Tag2?: string;
  Tag3?: string;
  Tag4?: string;
  Tag5?: string;
  Tag6?: string;
  Tag7?: string;
  Tag8?: string;
  Tag9?: string;
  Tag10?: string;
  forceXsdValidation?: boolean;
  anonymize?: boolean;
  requiresBatchDispatch?: boolean;
  vatDate?: Date;
  ansiCode?: string;
  ManualUpload?: boolean;
  importOnlyValid: boolean;
  TTag1?: string;
  TTag2?: string;
  TTag3?: string;
  TTag4?: string;
  TTag5?: string;
}

interface DispatchType {
  Id?: string;
}

export enum ImportMode {
  All,
  OnlyWithPostImportAccess
}

export const postInvoices = async ({ organizationId, postInvoiceDto, dispatchType, getImportMode }: {
  organizationId: string;
  postInvoiceDto: PostInvoiceDto;
  dispatchType?: DispatchType;
  getImportMode: () => Promise<ImportMode>;
}): Promise<void> => {
  let validatePostImportAccess = true;
  let importMode: ImportMode | undefined = undefined;

  const sendFile = async (x: File) => {
    const fileData = new FormData();
    fileData.append("file", x, x.name);
    fileData.append("manualUpload", postInvoiceDto.manualUpload.toString());
    fileData.append("dispatchType", dispatchType?.Id ?? "");
    fileData.append("validatePostImportAccess", validatePostImportAccess.toString());
    if (postInvoiceDto.vatDate) fileData.append("vatDate", parseDate(postInvoiceDto.vatDate));
    if (postInvoiceDto.Tag1) fileData.append("Tag1", postInvoiceDto.Tag1);
    if (postInvoiceDto.Tag2) fileData.append("Tag2", postInvoiceDto.Tag2);
    if (postInvoiceDto.Tag3) fileData.append("Tag3", postInvoiceDto.Tag3);
    if (postInvoiceDto.Tag4) fileData.append("Tag4", postInvoiceDto.Tag4);
    if (postInvoiceDto.Tag5) fileData.append("Tag5", postInvoiceDto.Tag5);
    if (postInvoiceDto.Tag6) fileData.append("Tag6", postInvoiceDto.Tag6);
    if (postInvoiceDto.Tag7) fileData.append("Tag7", postInvoiceDto.Tag7);
    if (postInvoiceDto.Tag8) fileData.append("Tag8", postInvoiceDto.Tag8);
    if (postInvoiceDto.Tag9) fileData.append("Tag9", postInvoiceDto.Tag9);
    if (postInvoiceDto.Tag10) fileData.append("Tag10", postInvoiceDto.Tag10);
    if (postInvoiceDto.TTag1) fileData.append("TTag1", postInvoiceDto.TTag1);
    if (postInvoiceDto.TTag2) fileData.append("TTag2", postInvoiceDto.TTag2);
    if (postInvoiceDto.TTag3) fileData.append("TTag3", postInvoiceDto.TTag3);
    if (postInvoiceDto.TTag4) fileData.append("TTag4", postInvoiceDto.TTag4);
    if (postInvoiceDto.TTag5) fileData.append("TTag5", postInvoiceDto.TTag5);
    if (postInvoiceDto.anonymize) fileData.append("Anonymize", postInvoiceDto.anonymize.toString());

    const endpoint = postInvoiceDto.draft ?
      InvoicesEndpoints.DraftImport(organizationId) :
      InvoicesEndpoints.Base(organizationId);
    await axios.post(endpoint, fileData, {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    });
  }

  let index = 0;
  while (index < postInvoiceDto.invoices.length) {
    let retry = false;
    const x = postInvoiceDto.invoices[index].file;
    try {
      await sendFile(x)
    } catch (error: any) {
      const responseCode = error.response?.status;
      if (responseCode === 409 && importMode === undefined) {
        importMode = await getImportMode()
        if (importMode === ImportMode.All) {
          validatePostImportAccess = false;
          retry = true;
        }
        switch (importMode) {
          case ImportMode.All:
            validatePostImportAccess = false;
            retry = true;
            break;
          case ImportMode.OnlyWithPostImportAccess:
            // no action needed
            break;
        }
      }
      else if (responseCode >= 400 && responseCode !== 403 && responseCode !== 409) {
        let message = `Błąd importu faktury ${x.name}`;
        const errorsString = error.response?.data?.XfxDetails?.join(', ');
        if (responseCode === 400 && errorsString && errorsString.trim().length !== 0) {
          message = errorsString;
        }
        Notify({
          message: message,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 3000);
      }
    }
    finally {
      if (!retry) index++;
    }
  }
};

export const postInvoicesFromTokensCSV = async ({ organizationId, postInvoiceDto, dispatchType, t }: {
  organizationId: string;
  postInvoiceDto: PostInvoicesTokensCsvDto;
  dispatchType?: DispatchType;
  t: any;
}): Promise<void> => {

  type NotifyStack = (typeof Notify)['arguments'][1];

  const position: NotifyStack['position'] = 'bottom center'
  const direction: NotifyStack['direction'] = 'up-push'

  const sendFile = async (x: File) => {

    const fileData = new FormData()

    fileData.append("File", x, x.name);
    fileData.append("AdjustTokens", postInvoiceDto.adjustTokens.toString())
    fileData.append("ImportOnlyValid", postInvoiceDto.importOnlyValid.toString())
    fileData.append("DispatchType", dispatchType?.Id ?? "")

    if (postInvoiceDto.Tag1) fileData.append("Tag1", postInvoiceDto.Tag1)
    if (postInvoiceDto.Tag2) fileData.append("Tag2", postInvoiceDto.Tag2)
    if (postInvoiceDto.Tag3) fileData.append("Tag3", postInvoiceDto.Tag3)
    if (postInvoiceDto.Tag4) fileData.append("Tag4", postInvoiceDto.Tag4)
    if (postInvoiceDto.Tag5) fileData.append("Tag5", postInvoiceDto.Tag5)
    if (postInvoiceDto.Tag6) fileData.append("Tag6", postInvoiceDto.Tag6)
    if (postInvoiceDto.Tag7) fileData.append("Tag7", postInvoiceDto.Tag7)
    if (postInvoiceDto.Tag8) fileData.append("Tag8", postInvoiceDto.Tag8)
    if (postInvoiceDto.Tag9) fileData.append("Tag9", postInvoiceDto.Tag9)
    if (postInvoiceDto.Tag10) fileData.append("Tag10", postInvoiceDto.Tag10)
    if (postInvoiceDto.TTag1) fileData.append("TTag1", postInvoiceDto.TTag1);
    if (postInvoiceDto.TTag2) fileData.append("TTag2", postInvoiceDto.TTag2);
    if (postInvoiceDto.TTag3) fileData.append("TTag3", postInvoiceDto.TTag3);
    if (postInvoiceDto.TTag4) fileData.append("TTag4", postInvoiceDto.TTag4);
    if (postInvoiceDto.TTag5) fileData.append("TTag5", postInvoiceDto.TTag5);

    if (postInvoiceDto.forceXsdValidation) fileData.append("ForceXsdValidation", postInvoiceDto.forceXsdValidation.toString())
    if (postInvoiceDto.anonymize) fileData.append("Anonymize", postInvoiceDto.anonymize.toString())
    if (postInvoiceDto.requiresBatchDispatch) fileData.append("RequiresBatchDispatch", postInvoiceDto.requiresBatchDispatch.toString())
    if (postInvoiceDto.vatDate) fileData.append("vatDate", parseDate(postInvoiceDto.vatDate))
    if (postInvoiceDto.ansiCode) fileData.append("ANSICode", postInvoiceDto.ansiCode.toString())
    if (postInvoiceDto.ManualUpload) fileData.append("ManualUpload", postInvoiceDto.ManualUpload.toString())

    const { data: { bulkData, hasErrors, hasWarnings, outputFile, outputFileName, totalInvoicesNo, validInvoicesNo, errorsNo, warningsNo } } = await axios.post(InvoicesEndpoints.BulkFromTokens(organizationId), fileData, {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    })

    return { bulkData, hasErrors, hasWarnings, outputFile, outputFileName, totalInvoicesNo, validInvoicesNo, errorsNo, warningsNo }
  }

  let index = 0;
  while (index < postInvoiceDto.invoices.length) {

    const x = postInvoiceDto.invoices[index].file

    try {
      const result = await sendFile(x)

      if (result.outputFile) {
        const decodedFile = new TextDecoder().decode(Buffer.from(result.outputFile, 'base64'))

        const downloadUrl = window.URL.createObjectURL(new Blob([decodedFile]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', result.outputFileName);

        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      let allRowsAreValid = (result.totalInvoicesNo && result.totalInvoicesNo > 0) &&
        (result.validInvoicesNo && result.validInvoicesNo > 0) &&
        result.totalInvoicesNo === result.validInvoicesNo

      if (allRowsAreValid) {
        Notify({
          message: `${result.hasWarnings ? t("#_Import-invoices-hasWarnings-savedInvoices") : t("#_Import-invoices-success")} ${result.validInvoicesNo}`,
          type: `${result.hasWarnings ? 'warning' : 'success'}`,
          displayTime: 5000,
        }, {
          position,
          direction,
        });
        return
      }
      else if (postInvoiceDto.importOnlyValid && result.hasErrors) {
        Notify({
          message: `${t("#_Import-invoices-hasErrors")}`,
          type: 'error',
          displayTime: 5000,
        }, {
          position,
          direction,
        });
        return
      }
      else if (!postInvoiceDto.importOnlyValid && result.validInvoicesNo > 0) {
        Notify({
          message: `${t("#_Import-invoices-hasWarnings-savedInvoices")} ${result.validInvoicesNo}`,
          type: 'warning',
          displayTime: 5000,
        }, {
          position,
          direction,
        });
        return
      }
    } catch (error: any) {
      const responseCode = error.response?.status;
      if (responseCode >= 400 && responseCode !== 403) {
        let message = `Błąd importu faktur ${x.name}`;
        Notify({
          message: message,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 3000);
      }
    }
    finally {
      index++;
    }
  }
}
