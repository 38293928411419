import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import ScrollView from 'devextreme-react/scroll-view';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import RadioGroup from 'devextreme-react/radio-group';
import useFormErrors from 'utils/useFormErrors';
import DropDownBox from 'devextreme-react/drop-down-box';
import OrganizationsDropDown from 'components/dropdown/OrganizationsDropDown';
import { XfXApi } from 'api/XfXApi';
import notify from "devextreme/ui/notify";
import List from 'devextreme-react/list';
import TextBox from 'devextreme-react/text-box';
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';

import { NotificationMode, NotificationPeriod } from 'api/openapi/api';
import "./styles.css"
import { NotificationEditorProps, NotificationEditorSettings, notificationSettingsDictionary, NotificationSettingsAllFalse, TsNotificationType, NotificationConfigurationDtoExtended } from "./types";
import { ConditionEditor } from "./ConditionEditor";
import { Intervals } from "./types"

const HiddenClassName = 'xfx-notification-field-hidden';
const readOnly = false;
const showColonAfterLabel = false;
const minColWidth = 300;



export const emptyNotification = (): NotificationConfigurationDtoExtended => ({
    id: undefined,
    name: "",
    notificationType: undefined,
    notificationMode: 0,
    notificationPeriod: Intervals[0].id as NotificationPeriod,
    attachPdf: false,
    attachXml: false,
    attachReport: false,
    sendToContractor: false,
    users: [],
    organizations: [],
    additionalEmails: [],
    activationConditions: "",
    recipientsTags: [],
    notificationStartTimeTmp: null,
});

export const createDateFromTime = (timeString: string | null | undefined): Date | null => {
    if (!timeString) {
        return null;
    }
    const timeParts = timeString.split(':').map(part => parseInt(part, 10));

    if (timeParts.length !== 3 || timeParts.some(isNaN)) {
        console.error("Time string is not in correct format", timeString);
        return null;
    }

    const [hours, minutes, seconds] = timeParts;

    const now = new Date();
    const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hours,
        minutes,
        seconds
    );

    return date;
}

interface NotificationEditorHandle {
    save: () => Promise<boolean>;
}

const NotificationEditor = forwardRef<NotificationEditorHandle, NotificationEditorProps>(({
    notificationId,
    userDataSource,
    userColums,
    allOrganizationsCustomStore,
    initialNotification
}, ref) => {
    const { t } = useTranslation();
    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();

    const tt = (key: string) => t(key) ?? "";

    const [notification, setNotification] = useState<NotificationConfigurationDtoExtended>(initialNotification);

    const [periodsVisible, setPeriodsVisible] = useState(initialNotification.notificationMode === NotificationMode.NUMBER_0);

    const [availableNotificationTypes, setAvailableNotificationTypes] = useState<{ id: number; name: string }[]>([]);
    const [notificationModes, setNotificationModes] = useState<any>();

    const notificationModesAll = [{ "id": 0, "name": t("NotificationKind-Periodic") }, { "id": 1, "name": t("NotificationKind-OnEvent") }]
    const notificationModesReduced = [{ "id": 1, "name": t("NotificationKind-OnEvent") }]

    const initialSettings = initialNotification.notificationType !== undefined ?
        notificationSettingsDictionary[initialNotification.notificationType] : NotificationSettingsAllFalse;
    const [currentEditorSettings, setCurrentEditorSettings] = useState<NotificationEditorSettings>(initialSettings);

    useEffect(() => {
        const types = Object.values(TsNotificationType)
            .filter(value => typeof value === 'number')
            .map(value => ({
                id: value as number,
                name: t("NotificationType-" + TsNotificationType[value as number])
            }));

        XfXApi.DictionaryInvoice.apiTenantIdDictionaryNotificationTypesGet(XfXApi.GetTenantId())
            .then((resp: any) => {
                setAvailableNotificationTypes(types.map(x => x.id).filter(x => resp.data.map((x: any) => x.key).includes(x)).map(value => ({
                    id: value as number,
                    name: t("NotificationType-" + TsNotificationType[value as number])
                })))
            })
    }, [t])

    useEffect(() => {
        setNotificationModes(currentEditorSettings.possibleCyclic ? notificationModesAll : notificationModesReduced)
    }, [currentEditorSettings])

    const usersRender = () => {
        return (
            <DataGrid
                dataSource={userDataSource}
                columns={userColums}
                hoverStateEnabled={true}
                selectedRowKeys={notification.users || []}
                onSelectionChanged={usersOnSelectionChanged}
                height="100%">
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode='multiple'
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode="virtual" preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    };

    const syncUsersSelection = (e: any) => {
        const selectedUsers = e.value || [];
        setNotification(oldVal => ({ ...oldVal, users: selectedUsers }));
    };

    const usersOnSelectionChanged = (e: any) => {
        setNotification(oldVal => ({ ...oldVal, users: (e.selectedRowKeys.length && e.selectedRowKeys) || [] }));
    };

    const typeOnSelectionChanged = (e: any) => {
        if (e.value === undefined || e.value === null) {
            setCurrentEditorSettings(NotificationSettingsAllFalse);
            return
        }

        const settings = notificationSettingsDictionary[e.value as TsNotificationType]

        setCurrentEditorSettings(settings);

        if (notification.notificationMode !== 1)
            modeOnSelectionChanged(1)
    };

    const modeOnSelectionChanged = (e: number) => {
        const isCyclic = e === 0;
        setPeriodsVisible(isCyclic);
        setNotification(oldVal => {
            let newValue = { ...oldVal, notificationMode: e as NotificationMode }
            if (isCyclic && (oldVal.notificationPeriod === 0 || !oldVal.notificationPeriod)) {
                newValue = { ...newValue, notificationPeriod: 2 as NotificationPeriod } // set 1h as default
            }
            return (newValue)
        });
    };

    const submit = async () => {
        try {
            setNotification(oldVal => ({ ...oldVal, id: notificationId?.toString() }));

            const tmp: any = notification.notificationStartTime
            if (tmp instanceof Date) {
                notification.notificationStartTime = tmp.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            }


            let notificationToSend = { ...notification, notificationStartTimeTmp: undefined };
            if (notificationToSend.notificationMode === NotificationMode.NUMBER_0) { // cyclic
                notificationToSend = {
                    ...notificationToSend,
                    attachPdf: undefined,
                    attachXml: undefined,
                    attachReport: undefined,
                };

            } else { // after event
                notificationToSend = {
                    ...notificationToSend,
                    notificationStartTime: undefined,
                    notificationPeriod: undefined,
                };
            }

            const response = notificationId
                ? await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationPut(XfXApi.GetTenantId(), notificationToSend)
                : await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationPost(XfXApi.GetTenantId(), notificationToSend);
            if (response.status === 200) {
                notify({
                    message: response.data.messages?.[0] ?? "Operation successful",
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);

            }
        } catch (error: any) {
            if (error.response.status === 400) {
                notify({
                    message: error.response.data.messages[0],
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000);
            } else if (error.response.status === 422) {
                setErrors(error.response.data);
            }

            return false;
        }

        return true;
    };

    const save = async () => await submit()

    useImperativeHandle(ref, () => ({ save }));



    const formFieldDataChanged = (e: any) => {
        if (e.dataField) {
            clearError(e.dataField);
        }
    };

    const textBoxRef = useRef<TextBox>(null);
    const AdditionalEmails = (value: string[], setValue: (value: string[]) => void, validationErrors: any, isValid: boolean) => {
        return (
            <>
                <TextBox
                    ref={textBoxRef}
                    onEnterKey={(x: any) => {
                        if (!x.component._changedValue) return;

                        const newValue = x.component._changedValue.trim();
                        if (newValue.length === 0) return;

                        let newMails = newValue.split(',')
                            .map((email: string) => email.trim())
                            .filter((email: string) => /^[\w.-]+@[\w.-]+\.[A-Za-z]{2,}$/.test(email));

                        newMails = Array.from(new Set(newMails));

                        const filtered = newMails.filter(
                            (m: string) => !value.includes(m)
                        );
                        if (filtered.length > 0) {
                            setValue([...value, ...filtered]);
                        }
                        textBoxRef.current?.instance.reset();
                    }}
                    validationErrors={validationErrors}
                    isValid={isValid}
                />
                <List
                    dataSource={value}
                    selectionMode="none"
                    itemDeleteMode="static"
                    allowItemDeleting={true}
                    onItemDeleted={({ itemData }) => {
                        setValue(value.filter(x => x !== itemData));
                    }}
                />
            </>
        );
    };

    return (
        <div>
            <ScrollView>
                <Form
                    id="form"
                    labelMode="outside"
                    readOnly={readOnly}
                    showColonAfterLabel={showColonAfterLabel}
                    minColWidth={minColWidth}
                    className={"xfx-form"}
                    formData={notification}
                    onFieldDataChanged={formFieldDataChanged}>
                    {/* 1. Nazwa powiadomienia */}
                    <GroupItem caption={tt("#_notificationconfiguration_18")}>
                        <SimpleItem key="nameBox" dataField="name" editorType="dxTextBox" cssClass="xfx-required"
                            editorOptions={{
                                value: notification.name,
                                validationErrors: getFirstError("name"),
                                isValid: noErrors("name")
                            }}>
                            <Label key="nameLabel" text={tt("#_notificationconfiguration_1")} />
                        </SimpleItem>
                    </GroupItem>

                    {/* 2. Rodzaj powiadomienia */}
                    <GroupItem caption={tt("#_notificationconfiguration_10")}>
                        <SimpleItem dataField="notificationType"
                            editorType="dxSelectBox"
                            editorOptions={{
                                key: "id",
                                dataSource: availableNotificationTypes,
                                displayExpr: "name",
                                valueExpr: "id",
                                value: notification.notificationType,
                                onValueChanged: typeOnSelectionChanged,
                                readOnly: false,
                                validationErrors: getFirstError("notificationType"),
                                isValid: noErrors("notificationType"),
                                itemTemplate: (data: any) => `<div style="white-space: normal; word-wrap: break-word;">${data.name}</div>` // ` ` important for proper render
                            }}>
                            <Label text={tt("#_notificationconfiguration_4")} />
                        </SimpleItem>
                    </GroupItem>

                    {/* 3. Lista organizacji */}
                    <GroupItem
                        caption={tt("#_notificationconfiguration_19")}
                        cssClass={currentEditorSettings.organizations ? '' : HiddenClassName}>
                        <SimpleItem cssClass="xfx-required">
                            <OrganizationsDropDown
                                value={notification.organizations}
                                setValue={v => setNotification(oldVal => ({ ...oldVal, organizations: v }))}
                                allOrganizations={allOrganizationsCustomStore}
                                t={t}
                                useFluentValidation={true}
                                validationErrors={getFirstError("organizations")}
                                isValid={noErrors("organizations")}
                                clearError={clearError}
                                clearErrorField={'organizations'} disabled={false} />
                        </SimpleItem>
                    </GroupItem>

                    {/* 4. Tryb wysyłki powiadomienia */}
                    <GroupItem
                        caption={tt("#_notificationconfiguration_31")}
                        cssClass={currentEditorSettings.eventConf ? '' : HiddenClassName}>
                        <SimpleItem cssClass="xfx-notification-mode">
                            <RadioGroup
                                value={notification.notificationMode}
                                onValueChange={modeOnSelectionChanged}
                                layout="vertical"
                                valueExpr="id"
                                displayExpr="name"
                                dataSource={notificationModes}
                                validationErrors={getFirstError("notificationMode")}
                                isValid={noErrors("notificationMode")}
                            />
                        </SimpleItem>

                        <GroupItem
                            colCount={2}
                            cssClass={periodsVisible ? '' : HiddenClassName}>
                            <SimpleItem
                                cssClass="xfx-notification-mode-settings"
                                dataField="notificationPeriod"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    key: "id",
                                    dataSource: Intervals,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: notification.notificationPeriod,
                                    readOnly: false,
                                }}
                            >
                                <Label text={tt("#_roleseditor_46")} />
                            </SimpleItem>

                            {/* Pole do wyboru godziny */}
                            <SimpleItem
                                dataField="notificationStartTimeTmp"
                                editorType="dxDateBox"
                                editorOptions={{
                                    width: "300px",
                                    type: "time",
                                    //  value: notification.notificationStartTimeTmp,
                                    readOnly: false,
                                    onValueChanged: (e: any) => {
                                        if (e.value === null) {
                                            setNotification((oldVal) => ({ ...oldVal, notificationStartTime: null }));
                                            return;
                                        }

                                        const timeOnly = e.value.toTimeString().split(' ')[0]; // "HH:MM:SS"
                                        setNotification((oldVal) => ({ ...oldVal, notificationStartTime: timeOnly }));
                                    },
                                }}
                            >
                                <Label text="Godzina rozpoczęcia" />
                            </SimpleItem>

                        </GroupItem>
                    </GroupItem>


                    {/* 5. Warunki uruchomienia */}
                    <GroupItem caption="Warunki uruchomienia"
                        cssClass={currentEditorSettings.condition ? '' : HiddenClassName}>
                        <ConditionEditor
                            defaultValue={notification.activationConditions ?? null}
                            setValue={x => { notification.activationConditions = JSON.stringify(x) }}></ConditionEditor>
                    </GroupItem>


                    {/* 6. Załącznik */}

                    <GroupItem
                        caption={tt("#_notificationconfiguration_16")}
                        cssClass={`${(!periodsVisible && (currentEditorSettings.pdfXml || currentEditorSettings.reportFile)) ? '' : HiddenClassName}`}
                        colCount={3}>

                        <SimpleItem
                            dataField="attachPdf"
                            editorType="dxCheckBox"
                            editorOptions={{
                                value: notification.attachPdf,
                                text: tt("#_notificationconfiguration_17")
                            }}
                            cssClass={currentEditorSettings.pdfXml ? '' : HiddenClassName}>
                            <Label visible={false} />
                        </SimpleItem>

                        <SimpleItem
                            dataField="attachXml"
                            editorType="dxCheckBox"
                            editorOptions={{
                                value: notification.attachXml,
                                text: "XML"
                            }}
                            cssClass={currentEditorSettings.pdfXml ? '' : HiddenClassName}>
                            <Label visible={false} />
                        </SimpleItem>

                        <SimpleItem
                            dataField="attachReport"
                            editorType="dxCheckBox"
                            editorOptions={{
                                value: notification.attachReport,
                                text: "Raport"
                            }}
                            cssClass={currentEditorSettings.reportFile ? '' : HiddenClassName}>
                            <Label visible={false} />
                        </SimpleItem>
                    </GroupItem>

                    {/* 7. Lista użytkowników */}
                    <GroupItem
                        caption={tt("#_notificationconfiguration_20")}
                        cssClass={currentEditorSettings.userList ? '' : HiddenClassName}>
                        <SimpleItem>
                            <DropDownBox
                                value={notification.users}
                                valueExpr="id"
                                deferRendering={false}
                                displayExpr="login"
                                placeholder={tt("#_roleseditor_46")}
                                showClearButton={true}
                                dataSource={userDataSource}
                                onValueChanged={syncUsersSelection}
                                contentRender={usersRender}
                                label={tt("#_roleseditor_47")}
                                labelMode="hidden"
                            />
                        </SimpleItem>
                    </GroupItem>

                    {/* 8. Dodatkowy email */}
                    <GroupItem
                        caption={tt("#_notificationconfiguration_30")}
                        cssClass={currentEditorSettings.additionalEmail ? '' : HiddenClassName}>
                        <SimpleItem itemType="group">
                            <Label text={tt("#_notificationconfiguration_33")} />
                        </SimpleItem>
                        <SimpleItem>
                            {AdditionalEmails(notification.additionalEmails || [],
                                e => { setNotification(oldVal => ({ ...oldVal, additionalEmails: e })) },
                                getFirstError("additionalEmails"),
                                noErrors("additionalEmails")
                            )}
                        </SimpleItem>
                    </GroupItem>

                    {/* 9. Checkbox Czy chcesz wysłać email do kontrahentów */}
                    <GroupItem
                        caption={tt("#_notificationconfiguration_32")}
                        cssClass={(!periodsVisible && currentEditorSettings.contractorEmail) ? '' : HiddenClassName}>
                        <SimpleItem dataField="sendToContractor"
                            editorType="dxCheckBox"
                            editorOptions={{ value: notification.sendToContractor }}>
                            <Label visible={false} />
                        </SimpleItem>
                    </GroupItem>

                    {/* 10. Odbiorcy */}
                    <GroupItem
                        caption="Odbiorcy (Pola TAG)"
                        cssClass={currentEditorSettings.recipientsInTags ? '' : HiddenClassName}>
                        <SimpleItem dataField="recipientsTags" editorType="dxTagBox"
                            editorOptions={{
                                dataSource: Array.from({ length: 15 }, (_, i) => ({ id: i + 1, name: i < 10 ? `Tag ${i + 1}` : `TTag ${i - 9}` })),
                                displayExpr: "name",
                                valueExpr: "name",
                                value: notification.recipientsTags,
                                searchEnabled: true,
                                showSelectionControls: true,
                            }}>
                            <Label text=" " />
                        </SimpleItem>
                    </GroupItem>
                </Form>
            </ScrollView>
        </div>
    )
});

export default NotificationEditor;
