import { useState, useRef } from 'react';
import { ToolbarItem } from 'devextreme-react/popup';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import List from 'devextreme-react/list';
import { Button } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import FileUploader from 'devextreme-react/file-uploader';
import GenericToolbar from '../../settings/Toolbars/GenericToolbar';
import CustomStore from 'devextreme/data/custom_store';
import { useTranslation } from 'react-i18next';
import { Template } from 'devextreme-react/core/template';

const fileExtensions = ['.xml'];
const fileTypes = ".xml"

const SalesProcessingImporter = ({ postInvoiceDto, setPostInvoiceDto, selectedFilesItemKeys, setSelectedFilesItemKeys, 
    fileStore, setFileStore, draft }) => {

    const { t } = useTranslation()
    const fileUploaderRef = useRef()
    const filesListRef = useRef()
    const [tags, setTags] = useState({
        Tag1: null,
        Tag2: null,
        Tag3: null,
        Tag4: null,
        Tag5: null,
        Tag6: null,
        Tag7: null,
        Tag8: null,
        Tag9: null,
        Tag10: null,
        TTag1: null,
        TTag2: null,
        TTag3: null,
        TTag4: null,
        TTag5: null
    })

    const [metaData, setMetaData] = useState({
        vatDate: null,
        anonymize: false
    })

    const deleteFile = ({ id }) => {
        fileStore.remove(id)
    }

    const deleteFiles = () => {
        selectedFilesItemKeys.forEach(id => {
            deleteFile({ id: id })
        })
    }

    const FileInfo = ({ id, file }) => {
        return <div className='moduleListItem'>
            <div>Nazwa: {file.name}</div>
            <Button icon="trash" onClick={() => deleteFile({ id })} />
        </div>
    }

    const uploadInvoiceFile = async (file) => {
        postInvoiceDto.invoices = [...postInvoiceDto.invoices, { id: crypto.randomUUID(), file: file }]
        setFileStore(makeAsyncDataSource(postInvoiceDto.invoices))
    }

    const onFilesSelectedItemKeysChange = (args) => {
        if (args.name === 'selectedItemKeys')
            setSelectedFilesItemKeys(args.value !== args.previousValue ? args.value : [])
    }

    function makeAsyncDataSource(data) {
        return new CustomStore({
            loadMode: 'raw',
            key: 'id',
            cacheRawData: false,
            remove: (key) => {
                setSelectedFilesItemKeys(selectedFilesItemKeys.filter(x => x.id !== key))
                fileUploaderRef?.current?.instance?.removeFile(data.filter(x => x.id === key)[0].file)

                data = data.filter(x => x.id !== key)
                setFileStore(makeAsyncDataSource(data.filter(x => x.id !== key)))

                postInvoiceDto.draft = draft
                postInvoiceDto.invoices = postInvoiceDto.invoices.filter(x => x.id !== key)
                setPostInvoiceDto({ ...postInvoiceDto, invoices: postInvoiceDto.invoices.filter(x => x.id !== key) })
            },
            load() {
                return data
            },
        })
    }

    const dateBoxEditorOptions = {
        type: 'date', pickerType: 'calendar',
        readOnly: false, visible: true,
        showDropDownButton: true, dropDownButtonTemplate: 'renderEventIcon',
        invalidDateMessage: t("invalidDateMessage"),
        acceptCustomValue: false,
        openOnFieldClick: true,
        showClearButton: true
    }

    const renderEventIcon = () => {
        return (
            <>
                <div className='dx-icon dx-icon-event'></div>
            </>
        );
    }

    const toolbarButtons = [
        { icon: 'trash', text: t("#_salesprocessingimporter_5"), location: 'before', disabled: selectedFilesItemKeys.length === 0, onClick: () => deleteFiles() },
    ]

    return <>
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={{
                icon: 'save',
                text: t("#_salesprocessingimporter_9")
            }}
        />
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: t("#_salesprocessingimporter_120"),

            }} />
        <ScrollView
            direction="vertical">
             <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons}></GenericToolbar>
                    <div className="file-uploader-block">
                        <FileUploader
                            ref={fileUploaderRef}
                            accept={fileTypes}
                            allowCanceling={true}
                            selectButtonText={t("#_salesprocessingimporter_11")}
                            labelText={t("#_salesprocessingimporter_12")}
                            multiple={true}
                            showFileList={false}
                            uploadMode="instantly"
                            uploadFile={async (file) => await uploadInvoiceFile(file)}
                            allowedFileExtensions={fileExtensions}
                            invalidFileExtensionMessage={t("#_salesprocessingimporter_14")}
                            uploadedMessage={t("#_salesprocessingimporter_15")}
                            readyToUploadMessage={t("#_salesprocessingimporter_16")}
                            uploadFailedMessage={t("#_salesprocessingimporter_17")}
                            name={'file'}
                        />
                    </div>
                    <List
                        ref={filesListRef}
                        dataSource={fileStore}
                        selectionMode='all'
                        selectAllMode='allPages'
                        showSelectionControls={true}
                        repaintChangesOnly={true}
                        visible={postInvoiceDto.invoices?.length > 0}
                        itemRender={(x) => FileInfo({ id: x.id, file: x.file })}
                        selectedItemKeys={selectedFilesItemKeys}
                        onOptionChanged={onFilesSelectedItemKeysChange}>
                    </List>
            <Form formData={metaData} onFieldDataChanged={x => {
                postInvoiceDto.draft = draft
                const updated = { ...postInvoiceDto, ...metaData }
                setPostInvoiceDto(updated)
                setMetaData(metaData)
            }}>
                <GroupItem colCount={5} colSpan={1}>
                    <SimpleItem dataField="vatDate" editorType="dxDateBox"
                        editorOptions={dateBoxEditorOptions}>
                        <Label text={t("column-vatdate")} />
                        <Template name='renderEventIcon' render={renderEventIcon} />
                    </SimpleItem>
                </GroupItem>
                <GroupItem colCount={5} colSpan={1}>
                    <SimpleItem dataField="anonymize" visible={!draft} cssClass="verticalAlignMiddle" editorType="dxCheckBox"
                        editorOptions={{
                            text: t("#_salesprocessingimporter_18")
                        }}>
                        <Label visible={false} />
                    </SimpleItem>
                </GroupItem>
            </Form>
            {!draft && <Form formData={tags} colCount={5} onFieldDataChanged={x => {
                postInvoiceDto.draft = draft
                const updated = { ...postInvoiceDto, ...tags }
                setPostInvoiceDto(updated)
                setTags(tags)
            }}></Form>}
        </ScrollView>
    </>
}

export default SalesProcessingImporter;
