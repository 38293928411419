import { NotificationConfigurationDto } from "api/openapi";

export interface NotificationEditorProps {
    notificationId: string | null;
    userDataSource: any;
    userColums: any;
    allOrganizationsCustomStore: any;
    initialNotification: NotificationConfigurationDtoExtended;
}

export interface NotificationConfigurationDtoExtended extends NotificationConfigurationDto {
    notificationStartTimeTmp: Date | null; // temporary field for time picker to avoid issues with timeonly type
}

export enum TsNotificationType {
    InvoiceDispatched = 0,
    InvoiceReceived = 1,
    InvoiceDuplicate = 2,
    InvoicesToAccept = 3,
    TechnicalErrorOccured = 4,
    InvoiceFormalError = 5,
    InvoiceKsefError = 6,
    InvoiceOffline = 7,
    InvoiceDisaster = 8,
    InvoiceInternalKsefixError = 9,
    DisasterStarted = 10,
    DisasterEnded = 11,
}


export type NotificationEditorSettings = {
    organizations: boolean;
    eventConf: boolean;
    condition: boolean;
    pdfXml: boolean;
    reportFile: boolean;
    userList: boolean;
    additionalEmail: boolean;
    recipientsInTags: boolean;
    contractorEmail: boolean;
    possibleCyclic: boolean,
    possibleEventDriven: boolean,
};

export const notificationSettingsDictionary: Record<TsNotificationType, NotificationEditorSettings> = {
    [TsNotificationType.InvoiceDispatched]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: true, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.InvoiceReceived]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.InvoiceDuplicate]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.InvoicesToAccept]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.TechnicalErrorOccured]: { organizations: true, eventConf: true, condition: false, pdfXml: false, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: false, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.InvoiceFormalError]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: true, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.InvoiceKsefError]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.InvoiceOffline]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: true, possibleCyclic: false, possibleEventDriven: true },
    [TsNotificationType.InvoiceDisaster]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: true, possibleCyclic: false, possibleEventDriven: true },
    [TsNotificationType.InvoiceInternalKsefixError]: { organizations: true, eventConf: true, condition: true, pdfXml: true, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: true, contractorEmail: false, possibleCyclic: true, possibleEventDriven: true },
    [TsNotificationType.DisasterStarted]: { organizations: false, eventConf: true, condition: false, pdfXml: false, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: false, contractorEmail: false, possibleCyclic: false, possibleEventDriven: true },
    [TsNotificationType.DisasterEnded]: { organizations: false, eventConf: true, condition: false, pdfXml: false, reportFile: false, userList: true, additionalEmail: true, recipientsInTags: false, contractorEmail: false, possibleCyclic: false, possibleEventDriven: true },
};

export const NotificationSettingsAllFalse = {
    organizations: false,
    eventConf: false,
    condition: false,
    pdfXml: false,
    reportFile: false,
    userList: false,
    additionalEmail: false,
    recipientsInTags: false,
    contractorEmail: false,
    possibleCyclic: false,
    possibleEventDriven: false,
}



export const Intervals = [
    {
        "id": 2,
        "name": "co godzinę"
    },
    {
        "id": 3,
        "name": "co 2 godziny"
    },
    {
        "id": 5,
        "name": "co 4 godziny"
    },
    {
        "id": 8,
        "name": "co 8 godzin"
    },
    {
        "id": 10,
        "name": "co 12 godzin"
    },
    {
        "id": 11,
        "name": "co 24 godziny"
    }
]