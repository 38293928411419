import React, { useState, useRef, useEffect } from 'react';
import { Form, RequiredRule, PatternRule, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { NumberBox } from "devextreme-react/number-box"
import List from 'devextreme-react/list';
import { Button } from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { AuthorizationTypes, getAuthorizationTypeNameByValue } from './AuthorizationTypes';
import { encode as base64_encode } from 'base-64';
import Validator from 'devextreme-react/validator';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { useTranslation } from 'react-i18next';
import {
    Column, Editing, Texts
} from 'devextreme-react/data-grid';
import { XfXApi } from '../../../api/XfXApi';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";
import OrganizationsDropDown from 'components/dropdown/OrganizationsDropDown.tsx'
import DataGrid, {
    Scrolling,
} from 'devextreme-react/data-grid';
import EventsDropDown from './EventsDropDown';
import useFormErrors from 'utils/useFormErrors';
import FileUploader from 'devextreme-react/file-uploader';
import { CheckBox } from 'devextreme-react';

const validationExitPathGroup = "validationExitPathGroup";

const ExitPathEditor = ({ exitPath, setExitPath, availableOrganizations, availableSystems, saveError,
    authorizationType, setAuthorizationType, popupTitle, setEditVisible, canSave, refresh, showToastError, 
    nullSymbol, exitPathsEvents }) => {

    const { t } = useTranslation()

    const [selectedExitPathEndpoint, setSelectedExitPathEndpoint] = useState(null)
    const [exitPathEndpointKey, setExitPathEndpointKey] = useState(null)
    const [exitPathEndpointValue, setExitPathEndpointValue] = useState(null)
    const [addExitPathDisabled, setAddExitPathDisabled] = useState(true)
    const [urlValidationError, setUrlValidationError] = useState('');
    const endpointTextBoxRef = useRef(null);

    const [basicAuthUsername, setBasicAuthUsername] = useState(null)
    const [basicAuthPassword, setBasicAuthPassword] = useState(null)
    const [basicAuthTextBoxesVisible, setBasicAuthTextBoxesVisible] = useState(false)
    const [passwordTextBoxMode, setPasswordTextBoxMode] = useState('password')

    const [tokenAuthTextBoxesVisible, setTokenAuthTextBoxesVisible] = useState(false)

    const [certCheckbox, setCertCheckBox] = useState(false)

    const [certificateCompontentsVisible, setCertificateCompontentsVisible] = useState(false)
    const [certificatePasswordTextBoxMode, setCertificatePasswordTextBoxMode] = useState('password')

    const certPasswordTextBoxRef = useRef(null)

    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const fileUploaderRef = useRef()

    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors()

    const headersGridRef = useRef(null);
    const vgForm = useRef(null);

    const fileExtensions = ['.pfx', '.p12'];
    const fileTypes = ".pfx,.p12"

    const wrapNull = (v) => v === null ? nullSymbol : v
    const unwrapNull = (v) => v === nullSymbol ? null : v

    useEffect(() => {
        setBasicAuthTextBoxesVisible(authorizationType === 0)
        setTokenAuthTextBoxesVisible(authorizationType === 1 || authorizationType === 2)
    }, [authorizationType]) 

    const deleteEndpoint = (endpoint) => {
        const dict = { ...exitPath.endpoints }
        delete dict[endpoint]

        const updated = {
            ...exitPath, endpoints: dict
        }

        setExitPath(updated)

        if (selectedExitPathEndpoint === endpoint) {
            setSelectedExitPathEndpoint(null)
        }
    }

    const addButton = {
        icon: 'add',
        disabled: addExitPathDisabled,
        onClick: () => addEndpoint()
    };

    const passwordButton = {
        icon: 'eye',
        onClick: () => setPasswordTextBoxMode(passwordTextBoxMode === 'text' ? 'password' : 'text')
    };

    const certificatePasswordButton = {
        icon: 'eye',
        onClick: () => setCertificatePasswordTextBoxMode(certificatePasswordTextBoxMode === 'text' ? 'password' : 'text')
    };

    const addEndpoint = () => {
        if (ValidationEngine.getGroupConfig(vgForm?.instance)) {
            const validation = vgForm?.current?.instance?.validate()
            if (!validation?.isValid)
                return
        }

        const updated = {
            ...exitPathEndpointValue, type: authorizationType === -1 ? null : authorizationType,
        }

        setExitPathEndpointValue(updated)

        const dict = { ...exitPath.endpoints }
        dict[exitPathEndpointKey] = exitPathEndpointValue

        const updatedEditPath = {
            ...exitPath, endpoints: dict
        }
        setExitPath(updatedEditPath)

        endpointTextBoxRef.current.instance.reset()
    }

    const EndpointInfo = (x) => {
        return <div className='moduleListItem'>
            <div>{getAuthorizationTypeToShow(x)} {getKeyToShow(x)} {getValueToShow(x)} {getCertNameToShow(x)} {getCertPasswordBase64ToShow(x)} {getAddressToShow(x)}</div>
            <Button icon="trash" onClick={() => deleteEndpoint(x)} />
        </div>
    }

    const getAuthorizationTypeToShow = (x) => {
        const description = <b>Autoryzacja:</b>
        return <div>{description} {getAuthorizationTypeNameByValue(exitPath.endpoints[x]?.type, t)}</div>
    }

    const getKeyToShow = (x) => {
        const key = exitPath.endpoints[x]?.key
        const description = <b>Klucz nagłówka:</b>
        return key !== null && key !== undefined ? <div>{description} {key}</div> : ''
    }

    const getValueToShow = (x) => {
        const value = exitPath.endpoints[x]?.value
        const description = <b>Wartość nagłówka:</b>
        return value !== null && value !== undefined ? <div>{description} {value}</div> : ''
    }

    const getAddressToShow = (x) => {
        const description = <b>Adres:</b>
        return x !== null && x !== undefined ? <div>{description} {x}</div> : ''
    }

    const getCertNameToShow = (x) => {
        const certName = exitPath.endpoints[x]?.certificate?.name
        const description = <b>Nazwa certyfikatu:</b>
        return certName !== null && certName !== undefined ? <div>{description} {certName}</div> : ''
    }

    const getCertPasswordBase64ToShow = (x) => {
        const certPasswordBase64 = exitPath.endpoints[x]?.certificate?.password
        const description = <b>Hasło certyfikatu (base64):</b>
        return certPasswordBase64 !== null && certPasswordBase64 !== undefined ? <div>{description} {certPasswordBase64}</div> : ''
    }


    const isValidUrl = (url) => {
        const exitPathUrlPattern = /^https?:\/\/[^\s$.?#].[^\s]*$/gm;
        return exitPathUrlPattern.test(url);
    }

    const exitPathEndpointValueChanged = (value) => {
        setExitPathEndpointKey(value)

        if (value?.length >= 4 && !(value in exitPath.endpoints)) {
            if (isValidUrl(value)) {
                setAddExitPathDisabled(false)
                setUrlValidationError('')
            } else {
                setAddExitPathDisabled(true)
                setUrlValidationError(t("Invalid URL"))
            }
        } else {
            setAddExitPathDisabled(true)
        }
    }

    const onAuthorizationChanged = (e) => {
        setAuthorizationType(e.value)

        if (!((e.value === 1 && e.previousValue === 2) || (e.value === 2 && e.previousValue === 1)))
        {
            if (exitPathEndpointValue?.key)
                Reflect.deleteProperty(exitPathEndpointValue, 'key')
    
            if (exitPathEndpointValue?.value)
                Reflect.deleteProperty(exitPathEndpointValue, 'value')
        }
        
        const updated = {
            ...exitPathEndpointValue, type: e.value !== -1 ? e.value: null,
        }

        setBasicAuthUsername(e.value !== 0 ? null : basicAuthUsername)
        setBasicAuthPassword(e.value !== 0 ? null : basicAuthPassword)

        setExitPathEndpointValue(updated)
    }

    const onBasicAuthUsernameChanged = (e) => {
        setBasicAuthUsername(e.value)

        const encoded = base64_encode((`${e.value ?? ''}:${basicAuthPassword ?? ''}`))
        const updated = {
            ...exitPathEndpointValue, value: `Basic ${encoded}`, key: 'authorization'
        }

        setExitPathEndpointValue(updated)
    }

    const onBasicAuthPasswordChanged = (e) => {
        setBasicAuthPassword(e.value)

        const encoded = base64_encode((`${basicAuthUsername ?? ''}:${e.value ?? ''}`))
        const updated = {
            ...exitPathEndpointValue, value: `Basic ${encoded}`, key: 'authorization'
        }

        setExitPathEndpointValue(updated)
    }

    const certDefined = () => {
        return exitPathEndpointValue?.certificate
    }

    const onCertificatePasswordChanged = (e) => {
        let updated = exitPathEndpointValue

        const val = e.value !== '' ? e.value : null
        const encodedPassword = val !== null ? base64_encode((`${e.value}`)) : null

        if (certDefined())
        {
            updated = {
                ...exitPathEndpointValue, certificate: {
                        ...exitPathEndpointValue.certificate, password: encodedPassword
                }
            }
        }
        else
        {
            if (encodedPassword !== null)
            {
                updated = {
                    ...exitPathEndpointValue, certificate: {
                        password: encodedPassword
                    }
                }
            }
        }

        setExitPathEndpointValue(updated)
    }

    const onCertCheckboxValueChange = (v) => {
        if (!v.value) {
            if (certDefined())
                Reflect.deleteProperty(exitPathEndpointValue, 'certificate')

            certPasswordTextBoxRef.current?.instance?.reset()
            fileUploaderRef.current?.instance?.reset()
        }
        setCertificateCompontentsVisible(v.value)
    }

    const addEmptyHeader = () => headersGridRef.current.instance.addRow();

    const saveButtonOptions = {
        text: t("#_exitpaths_24"),
        onClick: async () => {
            let headersGrid = headersGridRef.current.instance;
            await headersGrid.saveEditData();

            await ((exitPath.id === null ?
                    XfXApi.ExitPath.apiTenantIdExitPathPost(XfXApi.GetTenantId(), exitPath) :
                    XfXApi.ExitPath.apiTenantIdExitPathPut(XfXApi.GetTenantId(), exitPath))
                .then(x => {
                    handleClosingPopup(true)
                })
                .catch(x => {
                    if (x.response.status !== 422)
                    {
                        showToastError(t("#_exitpaths_10"))
                        handleClosingPopup(true)
                    }
                    else {
                        setErrors(x.response.data)
                    }                 
                }))

            
        },
        disabled: !canSave
    }

    const handleClosingPopup = (withRefresh) => {
        if (withRefresh)
            refresh()

        popup.close()
        
        setAuthorizationType(-1)
    }

    const cancelButtonOptions = {
        text: t("#_exitpaths_27"),
        onClick: () => {
            handleClosingPopup(false)
        }
    };

    const popupToolbarItems = commonPopupToolbarItems({
        t: t,
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions
    })

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => resolve(reader.result.replace('data:', '')
                                                        .replace(/^.+,/, ''))
          reader.onerror = error => reject(error);
        });
      }

    const onUploadFile = (file, data) => {
        let updated

        if (certDefined())
        {
            updated = {
                ...exitPathEndpointValue, certificate: {
                         ...exitPathEndpointValue.certificate, name: file.name, data: data
                }
            }
        }
        else
        {
            updated = {
                ...exitPathEndpointValue, certificate: {
                    data: data,
                    name: file.name
                }
            }
        }
                                            
        setExitPathEndpointValue(updated)
    }

    const popup = GenericPopup({
        onHiding: () => {
            handleClosingPopup(false)
        },
        content:
            <div>
                <Form
                    id="form"
                    visible={true}
                    colCount={3}
                    ref={vgForm}
                    validationGroup={validationExitPathGroup}>
                    <GroupItem caption={t("#_exitpatheditor_16")}>
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    label={t("#_exitpatheditor_17")}
                                    value={exitPath.name}
                                    onValueChanged={v => setExitPath({ ...exitPath, name: unwrapNull(v.value) })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <OrganizationsDropDown
                                    value={wrapNull(exitPath.nip)}
                                    setValue={v => setExitPath(oldVal => ({ ...oldVal, nip: unwrapNull(v[0]) }))}
                                    allOrganizations={availableOrganizations}
                                    t={t}
                                    placeholder={t("#_organizationsyncsettings_addeditpopup_19")}
                                    label={t("#_organizationsyncsettings_addeditpopup_9")}
                                    mode={'single'}
                                    dropDownValueExpr={'nip'}
                                />
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <SelectBox
                                    items={availableSystems}
                                    placeholder={t("#_exitpatheditor_20")}
                                    showClearButton={false}
                                    value={wrapNull(exitPath.fkSystem)}
                                    label={t("#_exitpatheditor_21")}
                                    onValueChanged={v => setExitPath({ ...exitPath, fkSystem: unwrapNull(v.value) })}
                                />
                            </div>
                        </div>
                    </GroupItem>
                    <GroupItem caption={t("#_exitpatheditor_22")}>
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <SelectBox
                                    dataSource={AuthorizationTypes(t)}
                                    value={authorizationType}
                                    onValueChanged={onAuthorizationChanged}
                                    displayExpr="Name"
                                    valueExpr="Value"
                                    label={t("#_exitpatheditor_23")}>
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <RequiredRule message={t("#_exitpatheditor_24")} />
                                    </Validator>
                                </SelectBox>
                            </div>
                        </div>
                        {basicAuthTextBoxesVisible && <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    label={t("#_exitpatheditor_25")}
                                    onValueChanged={onBasicAuthUsernameChanged}
                                >
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <RequiredRule message={t("#_exitpatheditor_26")} />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>}
                        {basicAuthTextBoxesVisible && <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    label={t("#_exitpatheditor_27")}
                                    mode={passwordTextBoxMode}
                                    onValueChanged={onBasicAuthPasswordChanged}>
                                    <TextBoxButton
                                        name="password"
                                        location="after"
                                        options={passwordButton}
                                    />
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <RequiredRule message={t("#_exitpatheditor_30")} />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>}
                        {tokenAuthTextBoxesVisible && <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    label={t("#_exitpatheditor_31")}
                                    onValueChanged={v => setExitPathEndpointValue(oldVal => ({...oldVal, key: v.value}))}>
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <RequiredRule message={t("#_exitpatheditor_32")} />
                                        <PatternRule
                                            message={t("#_exitpatheditor_33")}
                                            pattern={/^[a-zA-Z]+$/}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>}
                        {tokenAuthTextBoxesVisible && <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    label={t("#_exitpatheditor_34")}
                                    onValueChanged={v => setExitPathEndpointValue(oldVal => ({...oldVal, value: v.value}))}>
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <RequiredRule message={t("#_exitpatheditor_35")} />
                                        <PatternRule
                                            message={t("#_exitpatheditor_36")}
                                            pattern={/^[a-zA-Z0-9]+$/}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>}
                        <div className="dx-field">
                            <div className="dx-field-value" style={{ width: "auto" }}>
                                <CheckBox
                                    defaultValue={certCheckbox}
                                    text={t("#_exitpatheditor_51")}
                                    onValueChanged={v => {onCertCheckboxValueChange(v)}}>
                                </CheckBox>
                            </div>
                        </div>
                        {certificateCompontentsVisible && <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    ref={certPasswordTextBoxRef}
                                    label={t("#_exitpatheditor_43")}
                                    mode={certificatePasswordTextBoxMode}
                                    onValueChanged={onCertificatePasswordChanged}>
                                    <TextBoxButton
                                        name="password"
                                        location="after"
                                        options={certificatePasswordButton}
                                    />
                                </TextBox>
                            </div>
                        </div>}
                        {certificateCompontentsVisible && <div className="dx-field">
                            <div className="dx-field-value">
                                <FileUploader
                                    ref={fileUploaderRef}
                                    accept={fileTypes}
                                    allowCanceling={false}
                                    selectButtonText={t("#_exitpatheditor_44")}
                                    labelText={t("#_exitpatheditor_45")}
                                    multiple={false}
                                    showFileList={true}
                                    uploadMode="instantly"
                                    uploadFile={async (file) => {
                                        getBase64(file)
                                            .then(data => { 
                                                onUploadFile(file, data)
                                            })
                                    }}
                                    allowedFileExtensions={fileExtensions}
                                    invalidFileExtensionMessage={t("#_exitpatheditor_46")}
                                    uploadedMessage={t("#_exitpatheditor_47")}
                                    readyToUploadMessage={t("#_exitpatheditor_48")}
                                    uploadFailedMessage={t("#_exitpatheditor_49")}
                                    name={'file'}>
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <RequiredRule message={t("#_exitpatheditor_50")} />
                                    </Validator>
                                </FileUploader>
                            </div>
                        </div>}
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <TextBox
                                    ref={endpointTextBoxRef}
                                    label={t("#_exitpatheditor_37")}
                                    onValueChanged={v => exitPathEndpointValueChanged(v.value)}
                                    valueChangeEvent="keyup">
                                    <TextBoxButton
                                        name="address"
                                        location="after"
                                        options={addButton}
                                    />
                                    <Validator validationGroup={validationExitPathGroup}>
                                        <PatternRule
                                            message={t("exitpatheditor_invalid_url")}
                                            pattern={/^https?:\/\/[^\s$.?#].[^\s]*$/gm}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>

                    </GroupItem>

                    <GroupItem caption={t("exitpatheditor_notifications-settings")}>
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <NumberBox
                                    min={0}
                                    defaultValue={exitPath.retryCount}
                                    label={t("exitpatheditor_retry_count")}
                                    onValueChanged={v => setExitPath({ ...exitPath, retryCount: v.value })}
                                    valueChangeEvent="keyup"
                                >
                                </NumberBox>
                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-value">
                                <NumberBox
                                    min={0}
                                    defaultValue={exitPath.retryInterval}
                                    label={t("exitpatheditor_retry_interval")}
                                    onValueChanged={v => setExitPath({ ...exitPath, retryInterval: v.value })}
                                    valueChangeEvent="keyup"
                                >
                                </NumberBox>
                            </div>
                        </div>
                    </GroupItem>
                    <GroupItem caption={t("exitpatheditor_event_choice")}>
                        <SimpleItem cssClass="xfx-required">
                            <EventsDropDown
                                value={exitPath.events}
                                setValue={v => setExitPath(oldVal => ({ ...oldVal, events: v }))}
                                eventsDataSource={exitPathsEvents}
                                t={t}
                                useFluentValidation={true}
                                validationErrors={getFirstError("events")}
                                isValid={noErrors("events")}
                                clearError={clearError}
                                clearErrorField={'events'}
                            >
                            </EventsDropDown>
                        </SimpleItem>
                    </GroupItem>

                    <GroupItem caption={t("#_exitpatheditor_38")}>
                        <Button icon="plus" stylingMode="outlined" style={{ marginBottom: '10px' }} onClick={() => addEmptyHeader()} />
                        <DataGrid
                            ref={headersGridRef}
                            showBorders={true}
                            dataSource={exitPath.headers}
                            repaintChangesOnly={true}
                        >
                            <Editing
                                refreshMode={"reshape"}
                                mode="cell"
                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}
                            >
                                <Texts
                                    confirmDeleteTitle={t("#_DeletionPopup_1")}
                                    confirmDeleteMessage={t("#_DeletionPopup")}
                                />
                            </Editing>
                            <Scrolling
                                mode="virtual"
                            />
                            <Column dataField="key" caption={t("#_exitpatheditor_39")}>
                            </Column>
                            <Column dataField="value" caption={t("#_exitpatheditor_40")}>
                            </Column>
                        </DataGrid>
                    </GroupItem>
                    <GroupItem caption={t("#_exitpatheditor_110")}>
                        <SimpleItem dataField="detailLogsEnabled" editorType="dxCheckBox"
                            editorOptions={{
                                value: exitPath.detailLogsEnabled,
                                onValueChanged: v => setExitPath({ ...exitPath, detailLogsEnabled: v.value })
                            }}>
                            <Label text={t("#_exitpatheditor_111")} />
                        </SimpleItem>
                        <SimpleItem dataField="logAuthData" editorType="dxCheckBox"
                            editorOptions={{
                                value: exitPath.logAuthData,
                                onValueChanged: v => setExitPath({ ...exitPath, logAuthData: v.value })
                            }}>
                            <Label text={t("#_exitpatheditor_112")} />
                        </SimpleItem>
                    </GroupItem>
                </Form>
                <div style={{ color: "red" }}>{saveError}</div>
                <List
                    dataSource={Object.keys(exitPath.endpoints) ?? []}
                    selectionMode='none'
                    repaintChangesOnly={true}
                    itemRender={EndpointInfo}>
                </List>
            </div>,
        toolbarItems: popupToolbarItems,
        title: popupTitle,
        isVisible: true,
        setIsVisible: setEditVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return (<>
        {popup.popup}
    </>)
}

export default ExitPathEditor;
