// buttons can be marked with wip flag to hide them. To show them in the UI use `localStorage.setItem('EnableWipFeatures', true);` in the browser console

import { copyKsefNumber, GetPdf, MarkAsReceived, ResendInvoice, GetInvoiceFileAsBlob, GetUpoFileAsBlob, CreateArchive, JobCreateArchiveType, CreateArchiveExtensions, GetUpoOnDemand } from "./InvoiceGridActions"
import { getUpoForInvoice } from "./Actions/GetUpoForInvoice"
import { getUpoForInvoicePdf } from "./Actions/GetUpoForInvoicePdf"
import { GetXml, OpenInvoiceXml, OpenUpoXml, OpenDraftXml, GetDraftXml } from 'pages/Invoices/Actions/GetXml'
import { rejectInvoices } from "pages/Invoices/Sale/SalesAcceptance/actions/reject"
import { acceptInvoices } from "pages/Invoices/Sale/SalesAcceptance/actions/accept"
import { EditorType, EditorTokensSource } from 'pages/Invoices/Editor/model'
import { sendDraft } from 'pages/Invoices/Editor/Actions/sendDraft'

export const getAllButtons = ({
  deleteDraft,
  organizationId,
  organization,
  selectedItem,
  selectedItems,
  setEventLogsVisible,
  setImporterVisible,
  setEditorVisible,
  setAttachmentsVisible,
  setInvoicePreviewUrl,
  navigate,
  showErrorRaport,
  downloadErrorRaport,
  showCreateBatchPopup,
  acceptanceUsersPopup,
  showTagsPopup,
  showExportPopup,
  usersManagement,
  reloadGrid,
  dataGridRef,
  setReceptionSyncVisible,
  hasPrivilege,
  t,
  lastUsedQueryParms,
  gridId,
  setImporterFromTokensVisible,
  migrateInvoices,
  getOrganizationFullNameByNip,
  setSelectedItems
}) => {
  const restrictedDataAccess = !hasPrivilege(/*Privileges.AllColumnsAccess*/"all_columns_access", organizationId)
  const canCreateNewInvoice = hasPrivilege(/*Privileges.DraftCreateNew*/"draft_create_new", organizationId)
  const isOrganizationDisabled = !organization?.isActive
  const ownsInvoice = selectedItem !== null &&
    ((selectedItem.KsefContext === organizationId && selectedItem.MigrationContext === null) || selectedItem.MigrationContext === organizationId)

  return [
    {
      id: "select-all", icon: 'selectall', text: t("button-select-all"),
      onClick: async () => { dataGridRef.current.instance.selectAll() },
      requireMultiSelection: true
    },
    {
      id: "mark-as-received", icon: 'check', text: t("button-mark-as-received"), disabled: isOrganizationDisabled || selectedItem === null || !hasPrivilege('reception_mark_as_received', organizationId),
      onClick: async () => { await MarkAsReceived({ t, organizationId, invoiceId: selectedItem?.Id, currentReceivedFlag: selectedItem?.ReceivedByExternalSystem, reloadGrid: reloadGrid }) }
    },
    {
      id: "import", icon: 'download', text: t("button-import"), disabled: isOrganizationDisabled || !hasPrivilege('dispatch_post_invoice', organizationId),
      onClick: async () => setImporterVisible(true)
    },
    {
      id: "import-from-csv", icon: 'download', text: t("button-import-from-csv"), disabled: isOrganizationDisabled || !hasPrivilege('dispatch_post_invoice', organizationId),
      onClick: async () => setImporterFromTokensVisible(true)
    },
    {
      id: "preview-xml", icon: 'find', text: t("button-preview-xml"), disabled: selectedItem === null || restrictedDataAccess,
      onClick: async () => { await OpenInvoiceXml({ organizationId, invoiceId: selectedItem?.Id, sales: selectedItem?.IsDispatch, pretty: true, t }) }
    },
    {
      id: "preview-draft-xml", icon: 'find', text: t("button-preview-xml"), disabled: selectedItem === null || restrictedDataAccess,
      onClick: async () => { await OpenDraftXml({ organizationId, draftId: selectedItem?.Id, sales: selectedItem?.IsDispatch, pretty: true, t }) }
    },
    {
      id: "download-xml", icon: 'save', text: t("button-download-xml"), disabled: selectedItems === null || selectedItems.length === 0 || restrictedDataAccess,
      onClick: async () => {
        selectedItems.length === 1 ? await GetXml({ organizationId, invoiceId: selectedItem?.Id, sales: selectedItem?.IsDispatch, pretty: true, t, ksefNumber: selectedItem?.KsefNumber, subject2VatNumber: selectedItem?.Subject2VatNumber })
          : await CreateArchive({
            organizationId,
            selectedCreateArchiveData: selectedItems.map(({ Id, IsDispatch, KsefNumber, Subject2VatNumber }) => ({ Id, IsDispatch, KsefNumber, Subject2VatNumber })),
            createArchiveType: JobCreateArchiveType.Invoice, extension: CreateArchiveExtensions.Xml, t, gridId
          })
      }
    },
    {
      id: "download-draft-xml", icon: 'save', text: t("button-download-xml"), disabled: selectedItem === null || restrictedDataAccess,
      onClick: async () => { await GetDraftXml({ organizationId, draftId: selectedItem?.Id, t }) }
    },
    {
      id: "preview-html", icon: 'pdffile', text: t("button-preview-html"), disabled: selectedItem === null || restrictedDataAccess,
      onClick: async () => { await GetInvoiceFileAsBlob({ organizationId, invoiceId: selectedItem?.Id, sales: selectedItem?.IsDispatch, setObjectUrl: setInvoicePreviewUrl, asHtml: true, t }) }
    },
    {
      id: "preview-pdf", icon: 'pdffile', text: t("button-preview-pdf"), disabled: selectedItem === null || restrictedDataAccess,
      onClick: async () => { await GetInvoiceFileAsBlob({ organizationId, invoiceId: selectedItem?.Id, sales: selectedItem?.IsDispatch, setObjectUrl: setInvoicePreviewUrl, t }) }
    },
    {
      id: "download-pdf", icon: 'pdffile', text: t("button-download-pdf"), disabled: selectedItems === null || selectedItems.length === 0 || restrictedDataAccess,
      onClick: async () => {
        selectedItems.length === 1 ? await GetPdf({ organizationId, invoiceId: selectedItem?.Id, sales: selectedItem?.IsDispatch, t, ksefNumber: selectedItem?.KsefNumber, subject2VatNumber: selectedItem?.Subject2VatNumber })
          : await CreateArchive({
            organizationId,
            selectedCreateArchiveData: selectedItems.map(({ Id, IsDispatch, KsefNumber, Subject2VatNumber }) => ({ Id, IsDispatch, KsefNumber, Subject2VatNumber })),
            createArchiveType: JobCreateArchiveType.Invoice, extension: CreateArchiveExtensions.Pdf, t, gridId
          })
      }
    },
    {
      id: "copy-ksef-number", icon: 'copy', text: t("button-copy-ksef-number"), disabled: selectedItem === null || selectedItem?.KsefNumber === null,
      onClick: async () => copyKsefNumber(selectedItem?.KsefNumber, t)
    },
    {
      id: "batch-send", icon: 'shareCustomIcon', text: t("button-batch-send"), disabled: isOrganizationDisabled || selectedItem === null || selectedItem?.BatchId === null || selectedItem?.BatchId === undefined,
      onClick: () =>
        selectedItem?.KsefContext === organizationId && selectedItem?.Subject1VatNumber === organizationId ? navigate({ pathname: '/dispatch-sale-batch-packages', search: '?Id=' + selectedItem?.BatchId }) :
          selectedItem?.KsefContext === organizationId && selectedItem?.Subject2VatNumber === organizationId && selectedItem?.SelfInvoicing ? navigate({ pathname: '/dispatch-selfinvoicing-batch-packages2', search: '?Id=' + selectedItem?.BatchId }) :
            navigate({ pathname: '/dispatch-other-batch-packages3', search: '?Id=' + selectedItem?.BatchId }) //assume that condition without extra checks only when current UI stays as it is (3 views for dispatch processes)
    },
    {
      id: "logs", icon: 'textdocument', text: t("button-logs"), disabled: selectedItem === null,
      onClick: async () => setEventLogsVisible(true)
    },
    {
      id: "attachments", icon: 'attach', text: t("button-attachments"), disabled: selectedItem === null,
      onClick: async () => setAttachmentsVisible(true)
    },

    {
      id: 'download-upo-xml', icon: 'save', text: t("button-download-upo-xml"), disabled: (selectedItems === null || selectedItems.length === 0) || (selectedItem !== null && (!selectedItem?.IsDispatch || selectedItem?.KsefNumber === null)) || restrictedDataAccess,
      onClick: async () => {
        selectedItems.length === 1 ? await getUpoForInvoice(organizationId, selectedItem?.Id, selectedItem?.KsefNumber)
          : await CreateArchive({
            organizationId,
            selectedCreateArchiveData: selectedItems.map(({ Id, IsDispatch, KsefNumber, Subject2VatNumber }) => ({ Id, IsDispatch, KsefNumber, Subject2VatNumber })),
            createArchiveType: JobCreateArchiveType.UPO, extension: CreateArchiveExtensions.Xml, t, gridId
          })
      }
    },
    {
      id: 'download-upo-pdf', icon: 'save', text: t("button-download-upo-pdf"), disabled: (selectedItems === null || selectedItems.length === 0) || (selectedItem !== null && (!selectedItem?.IsDispatch || selectedItem?.KsefNumber === null)) || restrictedDataAccess,
      onClick: async () => {
        selectedItems.length === 1 ? await getUpoForInvoicePdf(organizationId, selectedItem?.Id, selectedItem?.KsefNumber)
          : await CreateArchive({
            organizationId,
            selectedCreateArchiveData: selectedItems.map(({ Id, IsDispatch, KsefNumber, Subject2VatNumber }) => ({ Id, IsDispatch, KsefNumber, Subject2VatNumber })),
            createArchiveType: JobCreateArchiveType.UPO, extension: CreateArchiveExtensions.Pdf, t, gridId
          })
      }
    },
    {
      id: 'preview-upo-xml', icon: 'save', text: t("button-preview-upo-xml"), disabled: selectedItem === null || !selectedItem?.IsDispatch,
      onClick: async () => { await OpenUpoXml({ organizationId, invoiceId: selectedItem?.Id }) }
    },
    {
      id: 'preview-upo-pdf', icon: 'save', text: t("button-preview-upo-pdf"), disabled: selectedItem === null || !selectedItem?.IsDispatch,
      onClick: async () => { await GetUpoFileAsBlob({ organizationId, invoiceId: selectedItem?.Id, setObjectUrl: setInvoicePreviewUrl, t }) }
    },
    {
      id: 'preview-error', icon: 'save', text: t("button-preview-error"), disabled: selectedItem === null,
      onClick: async () => { await showErrorRaport() }
    },
    {
      id: 'download-error', icon: 'save', text: t("button-download-error"), disabled: selectedItem === null,
      onClick: async () => { await downloadErrorRaport() }
    },

    {
      id: 'show-accepters', icon: 'users', text: t("button-show-accepters"), disabled: selectedItem === null || !usersManagement,
      onClick: async () => acceptanceUsersPopup.show(selectedItem.RequiresManualAcceptCount, selectedItem.RequiresManualAcceptUsersStr)
    },

    { id: 'sync', icon: 'syncCustom', text: t("button-sync"), disabled: isOrganizationDisabled || organizationId === null, onClick: async () => { setReceptionSyncVisible(true) } },

    { id: 'get-upo-on-demand', icon: 'syncCustom', text: t("button-get-upo-on-demand"), disabled: isOrganizationDisabled || organizationId === null, onClick: async () => { GetUpoOnDemand(organizationId, t) } },

    {
      id: 'accept-selected',
      icon: 'checkCircle', text: t("button-accept-selected"),
      disabled: isOrganizationDisabled || selectedItems === null || selectedItems.length === 0 || !selectedItems.map(x => x.ManualAcceptOptions).every(v => v === 'ToAccept'),
      onClick: async () => {
        await acceptInvoices({ organizationId, selectedInvoiceIdsWithIsDispatch: selectedItems.map(({ Id, IsDispatch }) => ({ Id, IsDispatch })), reloadGrid, t, setSelectedItems })
      },
      requireMultiSelection: true
    },
    {
      id: 'revoke-selected',
      icon: 'xCircle', text: t("button-revoke-selected"),
      disabled: isOrganizationDisabled || selectedItems === null || selectedItems.length === 0 || !selectedItems.map(x => x.ManualAcceptOptions).every(v => v === 'ToAccept'),
      onClick: async () => {
        await rejectInvoices({ organizationId, selectedInvoiceIdsWithIsDispatch: selectedItems.map(({ Id, IsDispatch }) => ({ Id, IsDispatch })), reloadGrid, t, setSelectedItems })
      },
      requireMultiSelection: true
    },
    {
      id: 'batch-create',
      icon: 'add',
      text: t("button-batch-create"),
      requireMultiSelection: true,
      disabled: isOrganizationDisabled || selectedItems === null || selectedItems.length === 0,
      onClick: async () => {
        showCreateBatchPopup()
        reloadGrid()
      }
    },
    {
      id: 'tags', icon: 'tags', text: t("button-tags"), disabled: isOrganizationDisabled || selectedItem === null || !hasPrivilege('dispatch_tags', organizationId),
      onClick: async () => {
        showTagsPopup({ organizationId, invoice: selectedItem, reloadGrid })
        reloadGrid()
      }
    },
    {
      id: 'export-rows-data', icon: 'export', text: t("button-export-rows"),
      onClick: async () => { showExportPopup() }
    },
    {
      id: 'resend-invoice', icon: 'send', text: t("button-resend"), disabled: isOrganizationDisabled || selectedItem === null || !hasPrivilege('dispatch_post_invoice', organizationId),
      onClick: async () => { await ResendInvoice({ t, organizationId, invoiceId: selectedItem?.Id, reloadGrid: reloadGrid }) }
    },
    {
      id: "edit", icon: 'edit', text: t("button-edit-invoice"), disabled: isOrganizationDisabled || selectedItem === null,
      onClick: async () => { } // not supported for now // await setEditorVisible(EditorType.Unknown, EditorTokensSource.Invoice),
    },
    {
      id: "correct", icon: 'edit', text: t("button-correct-invoice"), disabled: isOrganizationDisabled || selectedItem === null || !ownsInvoice || !canCreateNewInvoice,
      onClick: async () => await setEditorVisible(EditorType.Correction, EditorTokensSource.Invoice),
    },
    {
      id: "correct-self-invoice", icon: 'edit', text: t("button-correct-invoice"), disabled: isOrganizationDisabled || selectedItem === null || !ownsInvoice || selectedItem.SelfInvoicing === false || !canCreateNewInvoice,
      onClick: async () => await setEditorVisible(EditorType.CorrectionSelfInvoice, EditorTokensSource.Invoice),
    },
    {
      id: "draft-import", icon: 'download', text: t("button-import"), disabled: isOrganizationDisabled || !canCreateNewInvoice,
      onClick: async () => setImporterVisible(true),
    },
    {
      id: "new-invoice", icon: 'edit', text: t("button-create-draft"), disabled: isOrganizationDisabled || !canCreateNewInvoice,
      onClick: async () => await setEditorVisible(EditorType.Vat, EditorTokensSource.NewInvoice),
    },
    {
      id: "edit-draft", icon: 'edit', text: t("button-edit-draft"), disabled: isOrganizationDisabled || selectedItem === null || !canCreateNewInvoice,
      onClick: async () => await setEditorVisible(EditorType.Unknown, EditorTokensSource.Draft),
    },
    {
      id: "delete-draft", icon: 'trash', text: t("button-delete-draft"), disabled: isOrganizationDisabled || selectedItem === null
      //|| !canCreateNewInvoice // probably in the future we will allow to delete drafts only for users with specific privileges
      ,
      onClick: async () => await deleteDraft(),
    },
    {
      id: "send-draft", icon: 'send', text: t("button-send-invoice"), disabled: (isOrganizationDisabled || selectedItem === null || selectedItem.IsValid === false || selectedItem.Status !== "Ready" || !hasPrivilege('dispatch_post_invoice', organizationId)),
      onClick: async () => { sendDraft(selectedItem?.Id, organizationId, reloadGrid, getOrganizationFullNameByNip, t) },
    },
    {
      id: "migrate-purchase-invoice", icon: 'movetofolder', text: t("button-migrate"),
      disabled: isOrganizationDisabled || !hasPrivilege("invoice_migration") || selectedItems === null || selectedItems.length === 0
        || (selectedItems.length > 0 && !selectedItems.some(item => item.SelfInvoicing)),
      onClick: async () => { migrateInvoices(selectedItems, organizationId, reloadGrid, t) },
    },
    {
      id: "migrate-sale-invoice", icon: 'movetofolder', text: t("button-migrate"),
      disabled: isOrganizationDisabled || !hasPrivilege("invoice_migration") || selectedItems === null || selectedItems.length === 0,
      onClick: async () => { migrateInvoices(selectedItems, organizationId, reloadGrid, t) },
    },
  ]
} 