import { useEffect, useState, useRef } from "react"
import ScrollView from 'devextreme-react/scroll-view';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import RadioGroup from 'devextreme-react/radio-group'
import useFormErrors from 'utils/useFormErrors';
import DropDownBox from 'devextreme-react/drop-down-box'
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'
import { XfXApi } from 'api/XfXApi';
import notify from "devextreme/ui/notify"
import List from 'devextreme-react/list';
import GenericPopup from "components/popup/GenericPopup"
import TextBox from 'devextreme-react/text-box'
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import { Template } from 'devextreme-react/core/template';
import FilterBuilder from 'devextreme-react/filter-builder';
import CustomStore from 'devextreme/data/custom_store';
import { GetOdataDataFilterString } from 'utils/dataSourceHelper'
import moment from 'moment';
import { convertToColumns } from 'pages/Invoices/GenericGrid'
import { colData } from './const';

const readOnly = false
const showColonAfterLabel = false
const minColWidth = 300

const emptyReportConfiguration = () => { 
    return {
        id: null,

        name: '',

        type: 0,
        dataSource: null,
        outputFileType: 0,
        period: null,
        initDate: null,
        lastCheckupDateUtc: null,

        filter: null,
        odataFilter: null,
        columns: [],

        organizationsIds: [],
        usersIds: [],

        additionalEmails: []
    }
}

const ReportBuilderEditor = ({
    setVisible,
    reportConfigurationId,
    userDataSource,
    userColums,
    reportTypes,
    reportTypesDataStore,
    reportPeriodsDataStore,
    allOrganizationsCustomStore,
    reportExportParametersOutputFileTypes,
    reportExportParametersOutputFileTypesDataStore,
    allReportExportParametersDataSources,
    allReportExportParametersDataSourcesDataStore,
    availableReportExportParametersDataSources,
    availableReportExportParametersDataSourcesDataStore,
    sendInvColumns,
    dispatchFailedInvColumns,
    repoDispatchColumns,
    receivedInvColumns,
    receivedFailedInvColumns,
    repositoryReceivedInvColumns,
    onSaved,
    visible,
    reportData,
    onHiding,
    setBlockAutoRefresh }) => {
    const { t } = useTranslation()

    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();

    const popupTitle = t("#_reportsconfiguration_13")

    const [reportConfiguration, setReportConfiguration] = useState(emptyReportConfiguration())

    const [periodicStuffVisible, setPeriodicStuffVisible] = useState(reportConfiguration?.type === 1)
    const [filterBuilderFields, setFilterBuilderFields] = useState(repoDispatchColumns)
    const [filterBuilderFieldsDataSource, setFilterBuilderFieldsDataSource] = useState()
    const [columns, setColumns] = useState(convertToColumns(colData(t)))

    const [clearValues, setClearValues] = useState(false)

    const vgForm = useRef(null)

    useEffect(() => {
        handleReportData(reportData)
    }, [])

    const handleReportData = (reportData) => {
        if (!reportData)
        {
            setReportConfiguration(emptyReportConfiguration())
            typeOnSelectionChanged({ value: reportConfiguration.type })
            setPeriodicStuffVisible(reportConfiguration.type === 1)
        }
        else
        {
            const { organizationsNIPs, usersEmails, organizationsNames, ...rest} = reportData
            const filter = rest.filter ? JSON.parse(rest.filter) : null

            if (filter)
                iterateAndAdjustObjectToDatetimes(filter)

            const columnsAdjusted = JSON.parse(rest.columns).map(x => x.name)

            const dataSource = 
                availableReportExportParametersDataSources.map(x => x.id).includes(rest.dataSource) 
                    ? rest.dataSource 
                    : null //this handles scenario when privilege has been revoked since creating this report config

            const repConfig = {
                ...rest, 
                columns: columnsAdjusted,
                filter: filter,
                dataSource: dataSource
            }

            setReportConfiguration(repConfig)
            setPeriodicStuffVisible(repConfig.type === 1)
        }
    }

    useEffect(() => {
        setFilterBuilderFieldsDataSource(makeAsyncDataSource(filterBuilderFields, 'dataField'))
    }, [filterBuilderFields])

    useEffect(() => {
        if (clearValues)
            setReportConfiguration(oldVal => ({ ...oldVal, filter: null, odataFilter: null, columns: [] }))

        setClearValues(false)
    }, [reportConfiguration.dataSource])

    const usersRender = () => {
        return (
            <DataGrid
                dataSource={userDataSource}
                columns={userColums}
                hoverStateEnabled={true}
                selectedRowKeys={reportConfiguration.usersIds}
                onSelectionChanged={usersOnSelectionChanged}
                height="100%">
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode='multiple'
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode="virtual" preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />
            </DataGrid>
        )
    }

    const columnsRender = () => {
        return (
            <DataGrid
                dataSource={filterBuilderFieldsDataSource}
                hoverStateEnabled={true}
                selectedRowKeys={reportConfiguration.columns}
                onSelectionChanged={columnsOnSelectionChanged}
                height="100%">
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode='multiple'
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode="virtual" preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />
                {columns}
            </DataGrid>
        )
    }

    const makeAsyncDataSource = (data, key) => {
        return new CustomStore({
          loadMode: 'raw',
          key: key ?? 'id',
          load() {
            return data
          }
        })
    }

    const syncUsersSelection = (e) => {
        let selectedUsers = e.value || []
        setReportConfiguration(oldVal => ({...oldVal, usersIds: selectedUsers}))
    }

    const syncColumnsSelection = (e) => {
        let selectedColumns = e.value || []
        setReportConfiguration(oldVal => ({...oldVal, columns: selectedColumns}))
    }

    const usersOnSelectionChanged = (e) => {
        setReportConfiguration(oldVal => ({...oldVal, usersIds: (e.selectedRowKeys.length && e.selectedRowKeys) || []}))
    }

    const columnsOnSelectionChanged = (e) => {
        let cols = (e.selectedRowKeys.length && e.selectedRowKeys) || []
        setReportConfiguration(oldVal => ({ ...oldVal, columns: cols }))
    }

    const typeOnSelectionChanged = (e) => {
        updateOptions({ type: e?.value ?? e })
        setReportConfiguration(oldVal => ({ ...oldVal, type: e?.value ?? e }))
    }

    const updateOptions = ({ type }) => {
        if (type === 0)
            setReportConfiguration(oldVal => ({ ...oldVal, period: null, initDate: null, lastCheckupDateUtc: null })) 

        setPeriodicStuffVisible(type === 1)
    }

    const saveButtonOptions = {
        text: t("#_notificationconfiguration_28"),
        onClick: async() => {
          await submit()
        }
    }

    const submit = async() => {
        try {
            setReportConfiguration(oldVal => ({...oldVal, id: reportConfigurationId}))

            const adjustedColumns = []

            filterBuilderFields.forEach(element => {
                if (reportConfiguration.columns.includes(element.dataField))
                    adjustedColumns.push({ name: element.dataField, caption: element.caption})
            })

            const requestAdjusted = {
                ...reportConfiguration, 
                columns: JSON.stringify(adjustedColumns), 
                filter: reportConfiguration.filter ? JSON.stringify(reportConfiguration.filter) : null
            }

            const response = reportConfigurationId 
                ? await XfXApi.ReportApi.apiTenantIdReportPut(XfXApi.GetTenantId(), requestAdjusted)
                : await XfXApi.ReportApi.apiTenantIdReportPost(XfXApi.GetTenantId(), requestAdjusted)
                
            if (response.status === 200) {
                notify({
                    message: reportConfigurationId ? t("#_reportsconfiguration_25") : t("#_reportsconfiguration_24"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000)

                popup.close()
                onSaved()
            }
        } catch (error) {
            if (error.response.status === 400) {
                notify({
                    message: (reportConfigurationId ? t("#_reportsconfiguration_27") : t("#_reportsconfiguration_26")) + `${error.response?.data?.message}`,
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000)  
            }
            else if (error.response.status === 422) {
                setErrors(error.response.data)
            }
        }
      }
      
    const cancelButtonOptions = {
      text: t("#_savecancelpopup_2"),
      onClick: () => { 
        popup.close()
        onHiding()
      }
    }

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField)
        }
    }

    const AdditionalEmails = (value, setValue, validationErrors, isValid) => {
        const ref = useRef()
         return <>
        <TextBox
            ref={ref}
            onEnterKey={(x) => {
                if (!x.component._changedValue) return

                var newValue = x.component._changedValue.trim()
                if (newValue.length === 0) return
                
                var newMails = newValue.split(',')
                var filtered = newMails.filter(
                   m=>!value.includes(m));
                if (filtered.length > 0) {
                   setValue([...value, ...filtered])
                   ref.current.instance.reset()
                }}}
            validationErrors={validationErrors}
            isValid={isValid}/>
        <List
            dataSource={value}
            selectionMode="none"
            itemDeleteMode="static"
            allowItemDeleting={true}
            onItemDeleted={({ itemData }) => {
                setValue(value.filter(x => x !== itemData))
            }}/>
        </> 
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
        t: t, 
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions
    })

    const dateBoxEditorOptions = { 
        type: 'datetime', pickerType: 'calendar', 
        readOnly: false, visible: true,
        showDropDownButton: true, dropDownButtonTemplate: 'renderEventIcon',
        invalidDateMessage: t("invalidDateMessage"),
        acceptCustomValue: false,
        openOnFieldClick: true,
        showClearButton: true,
        min: new Date(),
        validationErrors: getFirstError("initDate"),
        isValid: noErrors("initDate"),
        onValueChanged: (v) => setReportConfiguration(oldVal => ({ ...oldVal, initDate: v.value }))
    }

    const renderEventIcon = () => {
        return (
          <>
            <div className='dx-icon dx-icon-event'></div>
          </>
        )
      }

    const filterBuilderValueChanged = (v) => {
        iterateAndAdjustObject(v.value)
        setReportConfiguration(oldVal => ({ ...oldVal, filter: v.value, odataFilter: GetOdataDataFilterString(v.value) }))
    }

    const iterateAndAdjustObject = (obj) => {
        for (var key in obj) {
            if (typeof obj[key] === "object" && obj[key] !== null)
            {
                if (obj[key] instanceof Date)
                    obj[key] = moment(obj[key]).toDate()
                else
                    iterateAndAdjustObject(obj[key])
            }
        }
    }

    const iterateAndAdjustObjectToDatetimes = (obj) => {
        var formats = [
            moment.ISO_8601
        ]

        for (var key in obj) {
            if (typeof obj[key] === "object" && obj[key] !== null)
                iterateAndAdjustObjectToDatetimes(obj[key])
            else if (moment(obj[key], formats, true).isValid())
                obj[key] = moment(obj[key]).toDate()
        }
    }

    const reportDataSourceValueChanged = (v) => {
        setFilterBuilderFields(getColumnsByDataSource({ dataSource: v?.value ?? v }))
        setClearValues(true)
    }

    const getColumnsByDataSource = ({ dataSource }) => {
        //to do handle 5 -> DispatchOtherFailed
        switch (dataSource) {
            case 0: //RepositorySales
            case 2: //RepositoryOther
                return repoDispatchColumns
            case 1: //RepositoryPurchase
                return repositoryReceivedInvColumns
            case 3: //DispatchSaleFailed
            case 4: //DispatchSelfInvoicingFailed
                return dispatchFailedInvColumns
            case 6: //ReceptionPurchaseRevokedGrid
            case 7: //ReceptionPurchaseP3Failed
            case 8: //ReceptionSelfInvoicingRevoked
                return receivedFailedInvColumns
            case 9: //DispatchSaleProcessing
            case 10: //DispatchSaleAcceptance
            case 11: //DispatchSaleBatchToSend
            case 12: //DispatchSelfInvoicingProcessing
            case 13: //DispatchSelfInvoicingAcceptance
            case 14: //DispatchSelfInvoicingBatchToSend
            case 24: //DispatchSaleProcessingOffline
            case 25: //DispatchSelfInvoicingProcessingOffline
                return sendInvColumns
            case 15: //ReceptionPurchaseProcessing
            case 16: //ReceptionPurchaseAlert
            case 17: //ReceptionPurchaseP3Processing
            case 18: //ReceptionPurchaseP3Alert
            case 19: //ReceptionSelfInvoicingSale
            case 20: //ReceptionSelfInvoicingAlert
            case 21: //ReceptionRepresentationProcessing
            case 22: //ReceptionSaleProcessing
            case 23: //ReceptionOtherProcessing
                return receivedInvColumns
            default:
                //To do think if we should throw some error/msg here? otherwise we pass columns that don't match chosen option
                return sendInvColumns
        }
    } 

    const popup = GenericPopup({ 
        onHiding: () => { 
            popup.close()
            onHiding()
        },
        content:
        <div>
            <ScrollView>
                <Form
                    id="form"
                    labelMode="outside"
                    readOnly={readOnly}
                    showColonAfterLabel={showColonAfterLabel}
                    minColWidth={minColWidth}
                    className={"xfx-form"}
                    formData={reportConfiguration}
                    onFieldDataChanged={formFieldDataChanged}
                    ref={vgForm}>
                    <GroupItem>
                            <SimpleItem key="nameBox" dataField="name" editorType="dxTextBox"  cssClass="xfx-required"
                                        value={reportConfiguration.name}
                                        editorOptions={{
                                        validationErrors: getFirstError("name"),
                                        isValid: noErrors("name"),
                                        placeholder: t("#_reportsconfiguration_7")
                                    }}>
                                        <Label key="nameLabel" text={t("#_reportsconfiguration_6")}/>
                            </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={t("#_reportsconfiguration_9")}>
                        <SimpleItem 
                            dataField="dataSource" 
                            editorType="dxSelectBox"
                            cssClass="xfx-required" 
                            value={reportConfiguration.dataSource}
                            editorOptions={{
                                key: "id",
                                dataSource: availableReportExportParametersDataSources,
                                onValueChanged: reportDataSourceValueChanged,
                                displayExpr: "name",
                                valueExpr: "id",
                                readOnly: false,
                                validationErrors: getFirstError("dataSource"),
                                isValid: noErrors("dataSource")}}>
                            <Label text={t("#_reportsconfiguration_7")} mode="floating"/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={t("#_reportsconfiguration_20")}>
                        <SimpleItem> 
                            <FilterBuilder //via editorType="dxFilterBuilder" groupOperations weren't stored to expected object
                                fields={filterBuilderFields}
                                value={reportConfiguration.filter}
                                onValueChanged={filterBuilderValueChanged}
                                validationErrors={getFirstError("filter")}
                                isValid={noErrors("filter")}>
                            </FilterBuilder>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={t("#_reportsconfiguration_21")}>
                        <SimpleItem>
                            <DropDownBox
                                dataField="columns" 
                                value={reportConfiguration.columns}//{columnsValues}
                                valueExpr="dataField"
                                deferRendering={false}
                                displayExpr="caption"
                                placeholder={t("#_reportsconfiguration_10")}
                                showClearButton={true}
                                dataSource={filterBuilderFields}
                                onValueChanged={syncColumnsSelection}
                                contentRender={columnsRender}
                                label={t("#_reportsconfiguration_21")}
                                labelMode="hidden"
                                validationErrors={getFirstError("columns")}
                                isValid={noErrors("columns")}/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={t("#_reportsconfiguration_12")}>
                        <SimpleItem>
                            <RadioGroup 
                                dataField="outputFileType"
                                value={reportConfiguration.outputFileType}
                                onValueChange={(v) => setReportConfiguration(oldVal => ({ ...oldVal, outputFileType: v }))}
                                layout="vertical"
                                valueExpr="id"
                                displayExpr="name"
                                dataSource={reportExportParametersOutputFileTypesDataStore}                            
                                editorOptions={{
                                    validationErrors: getFirstError("outputFileType"),
                                    isValid: noErrors("outputFileType")
                                }}
                            />
                        </SimpleItem>
                    </GroupItem> 
                    <GroupItem caption={t("#_reportsconfiguration_5")}>
                        <SimpleItem>
                            <RadioGroup 
                                dataField="type"
                                value={reportConfiguration.type}
                                onValueChange={typeOnSelectionChanged}
                                layout="vertical"
                                valueExpr="id"
                                displayExpr="name"
                                dataSource={reportTypesDataStore}                            
                                editorOptions={{
                                    validationErrors: getFirstError("type"),
                                    isValid: noErrors("type")
                                }}
                            />
                        </SimpleItem>
                    </GroupItem> 
                    {periodicStuffVisible && 
                        <GroupItem caption={t("#_reportsconfiguration_15")}>
                            <SimpleItem dataField="period" 
                                editorType="dxSelectBox"
                                cssClass="xfx-required" 
                                value={reportConfiguration.period}
                                editorOptions={{
                                    key: "id",
                                    dataSource: reportPeriodsDataStore,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    readOnly: false,
                                    placeholder: t("#_reportsconfiguration_10"),
                                    validationErrors: getFirstError("period"),
                                    isValid: noErrors("period")
                                }}>
                                <Label text={t("#_reportsconfiguration_16")} mode="floating"/>
                            </SimpleItem>
                            <SimpleItem 
                                dataField="initDate"
                                editorType="dxDateBox"
                                cssClass="xfx-required"
                                editorOptions = {dateBoxEditorOptions}>
                                <Template 
                                    name='renderEventIcon' 
                                    render={renderEventIcon} />
                                <Label text={t("#_reportsconfiguration_23")} mode="floating"/>
                            </SimpleItem>
                        </GroupItem>
                    }   
                    <GroupItem caption={t("#_reportsconfiguration_14")}>
                        <SimpleItem cssClass="xfx-required">
                            <OrganizationsDropDown
                                value={reportConfiguration.organizationsIds}
                                setValue={v => setReportConfiguration(oldVal => ({...oldVal, organizationsIds: v }))}
                                allOrganizations={allOrganizationsCustomStore}
                                t={t}
                                useFluentValidation={true}
                                validationErrors={getFirstError("organizationsIds")}
                                isValid={noErrors("organizationsIds")}
                                clearError={clearError}
                                clearErrorField={'organizationsIds'}
                            />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={t("#_reportsconfiguration_19")}>
                        <SimpleItem>
                            <DropDownBox
                                dataField="usersIds" 
                                value={reportConfiguration.usersIds}
                                valueExpr="id"
                                deferRendering={false}
                                displayExpr="login"
                                placeholder={t("#_reportsconfiguration_10")}
                                showClearButton={true}
                                dataSource={userDataSource}
                                onValueChanged={syncUsersSelection}
                                contentRender={usersRender}
                                label={t("#_reportsconfiguration_11")}
                                labelMode="hidden"/>
                        </SimpleItem>
                    </GroupItem>
                        <GroupItem caption={t("#_reportsconfiguration_17")}>
                            <SimpleItem itemType="group">
                                <Label text={t("#_reportsconfiguration_18")} />
                            </SimpleItem>
                            <SimpleItem>
                                {AdditionalEmails(
                                    reportConfiguration.additionalEmails,
                                    e => { setReportConfiguration(oldVal => ({...oldVal, additionalEmails: e}))},
                                    getFirstError("additionalEmails"),
                                    noErrors("additionalEmails")
                                )}
                        </SimpleItem>
                    </GroupItem>
                </Form>
            </ScrollView>
        </div>,
        toolbarItems: popupToolbarItems,
        title: popupTitle,
        width: "800px",
        height: "80%",
        isVisible: visible,
        setIsVisible: setVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    return ( 
        <>
            {popup.popup}
        </>
     )
}

export default ReportBuilderEditor